
const allowedValues = ['1', '2', '3', '4', '5', '6', '7', '14', '42', '60', '90', '120', '150', '180', '270', '360'];

const deliveryTimeframe = allowedValues.map(value => ({
    value: value,
    label: `${value} Tage`
}));
const subscriptionDeliveryTimeframeInDays = allowedValues.map(value => ({
    value: String(value),
    label: `${value} Tage`
}));

const displaySubscriptionCost = (costString: string) => {
    const [rate, months, kilometers] = costString.split('#');

    return (
        <div>
            <label className="horizontal-label">
                <span className="label-text">Rate (EUR brutto)</span>
                <p className="editable-input"> {rate}</p>
                <span className="edit-icon">
                    <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
                </span>
            </label>
            <label className="horizontal-label">
                <span className="label-text">Duration (Months)</span>
                <p className="editable-input">{months}</p>
                <span className="edit-icon">
                    <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
                </span>
            </label>
            <label className="horizontal-label">
                <span className="label-text">Kilometers per Year</span>
                <p className="editable-input">{kilometers}</p>
                <span className="edit-icon">
                    <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
                </span>
            </label>
        </div>
    );
};

export const options = {

    vatRate: [
        { value: 0, label: 'Mwst ausweisbar' },
        { value: 1, label: 'Mwst nicht ausweisbar' },
    ],
    climateControl: [
        { value: 0, label: 'Keine Klimaanlage oder Klimaautomatik' },
        { value: 1, label: 'Klimaanlage' },
        { value: 2, label: 'Klimaautomatik' },
    ],
    emissionSticker: [
        { value: 1, label: 'keine Plakette' },
        { value: 2, label: 'rot' },
        { value: 3, label: 'gelb' },
        { value: 4, label: 'grün' },
    ],
    driveType: [
        { value: 1, label: 'Kette' },
        { value: 2, label: 'Kardan' },
        { value: 3, label: 'Riemen' },
    ],
    emissionStandard: [
        { value: 1, label: 'euro1' },
        { value: 2, label: 'euro2' },
        { value: 3, label: 'euro3' },
        { value: 4, label: 'euro4' },
        { value: 5, label: 'euro5' },
        { value: 6, label: 'euro6' },
        { value: 7, label: 'euro7' },
    ],
    cabinType: [
        { value: 1, label: 'Doppelkabine' },
        { value: 2, label: 'Liegeplatz' },
        { value: 3, label: 'Nahverkehr' },
        { value: 4, label: 'Fernverkehr' },
    ],
    hydraulicSystem: [
        { value: 1, label: 'andere Hydraulik' },
        { value: 2, label: 'Kipphydraulik' },
        { value: 3, label: 'Schubbodenhydraulik' },
        { value: 4, label: 'Tankwagenhydraulik' },
    ],
    wheelFormula: [
        { value: 0, label: 'beliebig' },
        { value: 1, label: '4x2' },
        { value: 2, label: '4x4' },
        { value: 3, label: '6x2' },
        { value: 4, label: '6x4' },
        { value: 5, label: '6x6' },
        { value: 6, label: '8x4' },
        { value: 7, label: '8x6' },
        { value: 9, label: '8x8' },
        { value: 10, label: '8x2' }, // nur Nutzfahrzeuge
    ],
    deliveryTimeframe,
    fuelType: [
        { value: 1, label: 'Benzin' },
        { value: 2, label: 'Diesel' },
        { value: 3, label: 'Autogas' },
        { value: 4, label: 'Erdgas' },
        { value: 6, label: 'Elektro' },
        { value: 7, label: 'Hybrid' },
        { value: 8, label: 'Wasserstoff' },
        { value: 9, label: 'Ethanol' },
        { value: 10, label: 'Hybrid-Diesel' },
        { value: 11, label: 'Bi-Fuel' },
        { value: 0, label: 'Andere' },
    ],
    transmissionType: [
        { value: 0, label: 'keine Angabe' },
        { value: 1, label: 'Schaltgetriebe' },
        { value: 2, label: 'Halbautomatik' },
        { value: 3, label: 'Automatik' },
    ],
    interiorColor: [
        { value: 1, label: 'Schwarz' },
        { value: 2, label: 'Grau' },
        { value: 3, label: 'Beige' },
        { value: 4, label: 'Braun' },
        { value: 5, label: 'Andere' },
        { value: 6, label: 'Weiß' },
        { value: 7, label: 'Blau' },
        { value: 8, label: 'Rot' },
        { value: 9, label: 'Gelb' },
        { value: 10, label: 'Grün' },
        { value: 11, label: 'Orange' },
    ],
    interiorType: [
        { value: 1, label: 'Leder' },
        { value: 2, label: 'Teilleder' },
        { value: 3, label: 'Stoff' },
        { value: 4, label: 'Velour' },
        { value: 5, label: 'Alcantara' },
        { value: 6, label: 'Andere' },
        { value: 7, label: 'Kunstleder' },
    ],
    airbagExtended: [
        { value: 2, label: 'Fahrer Airbag' },
        { value: 3, label: 'Vordere Airbags' },
        { value: 4, label: 'Vorder und Seiten Airbags' },
        { value: 5, label: 'Vorder und Seiten und weitere Airbags' },
    ],
    installmentInsurance: [
        { value: 0, label: 'Ratenabsicherung nicht enthalten' },
        { value: 1, label: 'Ratenabsicherung entspricht dem angegebenen Betrag' },
    ],
    interestRateType: [
        { value: 1, label: 'gebunden' },
        { value: 2, label: 'veränderlich' },
        { value: 3, label: 'kombiniert' },
    ],
    energyEfficiencyClass: [
        { value: 'A+++', label: 'A+++' },
        { value: 'A++', label: 'A++' },
        { value: 'A+', label: 'A+' },
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
    ],
    fuelGrade: [
        { value: 'NORMAL', label: 'NORMAL' },
        { value: 'SUPER', label: 'SUPER' },
        { value: 'SUPER_PLUS', label: 'SUPER_PLUS' },
    ],
    redPencilPrice: [
        { value: 0, label: 'nicht gebucht' },
        { value: 1, label: 'gebucht' },
    ],
    ebayExport: [
        { value: 0, label: 'nicht gebucht' },
        { value: 1, label: 'gebucht' },
    ],
    durationInMonths: [
        { value: 12, label: '12 Monate' },
        { value: 24, label: '24 Monate' },
        { value: 36, label: '36 Monate' },
        { value: 48, label: '48 Monate' },
        { value: 60, label: '60 Monate' },
        { value: 72, label: '72 Monate' },
        { value: 84, label: '84 Monate' },
        { value: 96, label: '96 Monate' },
    ],
    targetGroup: [
        { value: 1, label: 'privat' },
        { value: 2, label: 'gewerblich' },
        { value: 3, label: 'beides' },
    ],
    emissionClassDetail: [
        { value: 'EURO6C', label: 'EURO6C' },
        { value: 'EURO6D_TEMP', label: 'EURO6D_TEMP' },
        { value: 'EURO6D', label: 'EURO6D' },
        { value: 'EURO6E', label: 'EURO6E' },
    ],
    maintenanceAndWearCombination: [
        { value: '0', label: 'inklusive' },
        { value: '', label: 'keine Angabe' },
    ],
    liabilityInsurancePerMonth: [
        { value: '0', label: 'inklusive' },
        { value: '', label: 'keine Angabe' },
    ],
    liabilityDeductible: [
        { value: '0', label: 'inklusive' },
        { value: '', label: 'keine Angabe' },
    ],
    fullCoveragePerMonth: [
        { value: '0', label: 'inklusive' },
        { value: '', label: 'keine Angabe' },
    ],
    fullCoverageDeductible: [
        { value: '0', label: 'inklusive' },
        { value: '', label: 'keine Angabe' },
    ],
    oneTimeVehicleDeliveryCost: [
        { value: '', label: 'keine Angabe' },
        { value: '0', label: 'inklusive' },
    ],
    vehicleTaxPerMonth: [
        { value: '', label: 'keine Angabe' },
        { value: '0', label: 'inklusive' },
    ],
    tireCostPerMonth: [
        { value: '', label: 'keine Angabe' },
        { value: '0', label: 'inklusive' },
    ],
    tuvCostPerMonth: [
        { value: '', label: 'keine Angabe' },
        { value: '0', label: 'inklusive' },
    ],
    returnInsurancePerMonth: [
        { value: '', label: 'keine Angabe' },
        { value: '0', label: 'inklusive' },
    ],
    oneTimeRegistrationCost: [
        { value: '', label: 'keine Angabe' },
        { value: '0', label: 'inklusive' },
    ],
    bafaBonus: [
        { value: '0', label: 'kein Bonus' },
        { value: '1', label: 'Bonusbetrag' }, // value should be set dynamically based on the bonus amount
    ],
    previousUse: [
        { value: 1, label: 'Fahrschule' },
        { value: 2, label: 'Taxi' },
        { value: 3, label: 'DE-Mietfahrzeug' },
        { value: 4, label: 'EU-Mietfahrzeug' },
        { value: 5, label: 'EU-Fahrzeug' },
    ],
    activeHeadrest: [
        { value: 0, label: 'ohne AktivKopfst.' },
        { value: 1, label: 'AktivKopfstützen vr' },
        { value: 2, label: 'AktivKopfstützen vr+hi' },
    ],
    autonomousDriving: [
        { value: 0, label: 'kein autonomes Fahren' },
        { value: 1, label: 'autonomes Fahren Level 1' },
        { value: 2, label: 'autonomes Fahren Level 2' },
        { value: 3, label: 'autonomes Fahren Level 3' },
        { value: 4, label: 'autonomes Fahren Level 4' },
        { value: 5, label: 'autonomes Fahren Level 5' },
    ],
    batteryType: [
        { value: 0, label: 'Lithium-Ionen' },
        { value: 1, label: 'Lithium-Polymer' },
        { value: 2, label: 'Nickelmetallhybrid' },
    ],
    chargingPlug: [
        { value: 0, label: 'Schuko' },
        { value: 1, label: 'Typ 1' },
        { value: 2, label: 'Typ 2' },
        { value: 3, label: 'Combo (CCS)' },
        { value: 4, label: 'CHAdeMO' },
        { value: 5, label: 'Typ 1 + CHAdeMO' },
        { value: 6, label: 'Typ 2 + CHAdeMO' },
        { value: 7, label: 'TESLA_SC' },
    ],
    subscriptionAvailability: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: 'sofort' },
        { value: 2, label: 'Innerhalb 14 Tage' },
        { value: 3, label: 'innerhalb 4 Wochen' },
        { value: 4, label: 'mehr als 4 Wochen' },
    ],
    subscriptionDeliveryDate: [
        { value: '', label: 'Datumsangabe erforderlich' },
    ],
    subscriptionDeliveryTimeframeInDays,
    displaySubscriptionCost,
    subscriptionPaymentTerms: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: 'Kreditkarte' },
        { value: 2, label: 'Bezahl.de' },
        { value: 3, label: 'Bankeinzug' },
    ],
    co2ClassWltp: [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
    ],
    weightedCo2ClassWltp: [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
    ],
    co2EmissionsWltpDischargedBattery: [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
    ],
    co2ClassWltpDischargedBattery: [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
    ],
    lengthType: [
        { value: 'L1', label: 'L1' },
        { value: 'L2', label: 'L2' },
        { value: 'L3', label: 'L3' },
        { value: 'L4', label: 'L4' },
        { value: 'L5', label: 'L5' }
    ],
    heightType: [
        { value: 'H1', label: 'H1' },
        { value: 'H2', label: 'H2' },
        { value: 'H3', label: 'H3' }
    ],
    wheelbaseType: [
        { value: 'SHORT', label: 'SHORT' },
        { value: 'MIDDLE', label: 'MIDDLE' },
        { value: 'LONG', label: 'LONG' },
        { value: 'EXTRA_LONG', label: 'EXTRA_LONG' }
    ],
    chargingPlugType: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: 'Schuko' },
        { value: 2, label: 'Typ 1' },
        { value: 3, label: 'Typ 2' },
        { value: 4, label: 'Combo (CCS)' },
        { value: 5, label: 'CHAdeMO' },
        { value: 6, label: 'Typ 1 + CHAdeMO' },
        { value: 7, label: 'Typ 2 + CHAdeMO' },
        { value: 8, label: 'TESLA_SC' }
    ],
    hitchLoadCapacity: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: '2.0t' },
        { value: 2, label: '2.8t' },
        { value: 3, label: '3.5t' },
        { value: 4, label: '2.5t' }
    ],
    trailerHitchType: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: 'Kugel' },
        { value: 2, label: 'Maul' }
    ],
    rearDoorType: [
        { value: 0, label: 'keine Hecktür' },
        { value: 1, label: 'Hecktür' },
        { value: 2, label: 'Hecktür 180°' },
        { value: 3, label: 'Hecktür 270°' },
        { value: 4, label: 'Hecktür 90°' },
        { value: 5, label: 'Hecktür 256°' },
        { value: 6, label: 'Heckklappe' }
    ],
    highRoofType: [
        { value: 0, label: 'keine Hecktür' },
        { value: 1, label: 'Hochdach' },
        { value: 2, label: 'Superhochdach' }
    ],
    conditionReport: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: 'sehr guter Zustand' },
        { value: 2, label: 'guter Zustand' },
        { value: 3, label: 'normaler Zustand' },
        { value: 4, label: 'mangelhafter Zustand' },
        { value: 5, label: 'sehr schlechter Zustand' }
    ],
    electricSeatMemoryFunction: [
        { value: 0, label: 'k.Angaben' },
        { value: 1, label: 'Fahrersitz' },
        { value: 2, label: 'Fahrer + Beifahrersitz' },
        { value: 3, label: 'Beifahrersitz' },
    ],
};