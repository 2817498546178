import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { storage, db } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, setDoc, doc, updateDoc } from 'firebase/firestore';
import './UploadImages.css';
import { User } from 'firebase/auth';

interface UploadImagesProps {
  user: User | null;
}

const UploadImages: React.FC<UploadImagesProps> = ({ user }) => {
  const { id, existingImagesCount } = useParams<{ id: string; existingImagesCount: string }>();
  const navigate = useNavigate();
  const [images, setImages] = useState<FileList | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImages(e.target.files);
      setProgress(0); // Initialize progress
    }
  };

  const handleUpload = async () => {
    if (images && id) {
      setLoading(true);
      const existingCount = parseInt(existingImagesCount ?? '0', 10);
      let totalBytesTransferred = 0;
      const totalBytes = Array.from(images).reduce((acc, image) => acc + image.size, 0);

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const timestamp = Date.now();
        const imagesCollection = collection(db, 'properties', id, 'imageURLs');
        const newImageDocRef = doc(imagesCollection);
        const key = newImageDocRef.id;
        const imageName = `image_property_${id}_${key}_${timestamp}`;
        const imageRef = ref(storage, `properties/${id}/${imageName}`);

        const uploadTask = uploadBytesResumable(imageRef, image);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const bytesTransferred = snapshot.bytesTransferred;
            const currentProgress = ((totalBytesTransferred + bytesTransferred) / totalBytes) * 100;
            setProgress(currentProgress);
          },
          (error) => {
            console.error('Upload failed:', error);
          },
          async () => {
            totalBytesTransferred += image.size;
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            const thumbUrl = url; // You can create a thumbnail URL if needed

            const imageDoc = {
              imageName: imageName,
              url,
              thumbUrl,
              orderNumber: existingCount + i,
            };

            await setDoc(newImageDocRef, imageDoc);

            // Set the main image URL if this is the first image
            if (i === 0) {
              const propertyDocRef = doc(db, 'properties', id);
              await updateDoc(propertyDocRef, {
                mainImageURL: url,
              });
            }

            if (i === images.length - 1) {
              setLoading(false);
              navigate(`/images/${id}`);
            }
          }
        );
      }
    }
  };

  return (
    <div className="upload-images-container">
      {/* <button className="back-button" onClick={() => navigate(-1)}>Zurück</button> */}
      <h2>Bilder hochladen</h2>
      <div className="file-input-container">
        <input type="file" multiple onChange={handleFileChange} id="file-input" className="file-input" />
        <label htmlFor="file-input" className="file-input-label">Dateien auswählen</label>
        {images && (
          <div className="file-input-filename">
            {Array.from(images).map((file, index) => (
              <span key={index} className="file-name">{file.name}</span>
            ))}
          </div>
        )}
      </div>
      {images && loading && (
        <div className="progress-container">
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: `${Math.min(progress, 100).toFixed(2)}%` }}></div>
            </div>
            <span>{Math.min(progress, 100).toFixed(2)}%</span>
          </div>
        </div>
      )}
      {loading && <div className="spinner"></div>}
      <button onClick={handleUpload} className="upload-button">Hochladen</button>
    </div>
  );
};

export default UploadImages;
