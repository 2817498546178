// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';



const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};


console.log('Firebase Config:', firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);
// const messaging = getMessaging(app);

// Request Permission to Send Notifications
// const requestPermission = async () => {
//   try {
//     await Notification.requestPermission();
//     const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
//     if (token) {
//       console.log('FCM Token:', token);
//       return token;
//     }
//   } catch (error) {
//     console.error('Unable to get permission to notify.', error);
//   }
//   return null;
// };

const showNotification = (payload: MessagePayload) => {
  if (Notification.permission === 'granted' && payload.notification) {
    const { title, body } = payload.notification;
    if (title && body) {
      new Notification(title, { body });
    }
  }
};

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   showNotification(payload);
// });

// Register the Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registration successful with scope: ', registration.scope);
    }).catch((err) => {
      console.log('Service Worker registration failed: ', err);
    });
}

// export { auth, googleProvider, db, storage, messaging, requestPermission };
export { auth, googleProvider, db, storage };
