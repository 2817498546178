import React, { useState, useEffect, useRef } from 'react';
import { User, deleteUser } from 'firebase/auth';
import { storage, db, auth } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, getDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import defaultProfileImage from '../assets/profile_default.png';
import { toast } from 'react-toastify';
import ReactCrop, { Crop, PercentCrop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface ProfileProps {
  user: User;
  setProfileImage: (url: string) => void;
}

interface UserProfile {
  firstName: string;
  lastName: string;
  sellerPhotoURL: string;
  accountType: string; // Changed to match the Firestore field
}

const Profile: React.FC<ProfileProps> = ({ user, setProfileImage }) => {
  const [localProfileImage, setLocalProfileImage] = useState<string>(defaultProfileImage);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [accountType, setAccountType] = useState<string>('Privat'); // Changed state name to accountType
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>();
  const [upImg, setUpImg] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data() as UserProfile;
          setLocalProfileImage(userData.sellerPhotoURL || defaultProfileImage);
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
          const initialAccountType = userData.accountType || 'PRIVATE';
          setAccountType(initialAccountType === 'COMMERCIAL' ? 'Gewerblich' : 'Privat'); // Set account type based on Firestore value

          // Update accountType if it's not set
          if (!userData.accountType) {
            await updateDoc(docRef, {
              accountType: 'PRIVATE',
              changedAt: Timestamp.now().toMillis(),
            });
          }
        } else {
          setLocalProfileImage(defaultProfileImage);
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1 / 1));
  };

  const onCropComplete = (crop: PixelCrop) => {
    setCompletedCrop(crop);
  };

  const onCropChange = (crop: Crop, percentCrop: PercentCrop) => {
    setCrop(percentCrop);
  };

  const handleSaveCrop = async () => {
    if (completedCrop && imageRef.current) {
      const canvas = document.createElement('canvas');
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(
          imageRef.current,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height
        );

        canvas.toBlob(async (blob) => {
          if (blob) {
            const newPath = `profileImages/${user.uid}/image_profile_seller_${user.uid}.jpg`;
            const storageRef = ref(storage, newPath);
            await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(storageRef);

            await updateDoc(doc(db, 'users', user.uid), {
              sellerPhotoURL: downloadURL,
            });

            setLocalProfileImage(downloadURL);
            setProfileImage(downloadURL);  // Update the profile image in the header
            setUpImg(null);
          }
        }, 'image/jpeg');
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Hiermit löschen Sie unwiderruflich Ihr Automatch Konto! Möchten Sie wirklich Ihr Automatch Konto löschen?')) {
      try {
        await deleteDoc(doc(db, 'users', user.uid));
        await deleteUser(user);
        toast.success('Konto erfolgreich gelöscht');
        await auth.signOut();
        navigate('/register');
      } catch (error) {
        toast.error('Fehler beim Löschen des Kontos');
        console.error('Error deleting account:', error);
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      toast.success('Logout erfolgreich');
      navigate('/login');
    } catch (error) {
      toast.error('Fehler beim Logout');
      console.error('Error during sign out:', error);
    }
  };

  const handleFAQClick = () => {
    window.open('https://automatchapp.de/faq', '_blank');
  };

  const handleKontoArtChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAccountType = e.target.value;
    setAccountType(newAccountType);

    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        accountType: newAccountType === "Gewerblich" ? "COMMERCIAL" : "PRIVATE",
        changedAt: Timestamp.now().toMillis(), // Change this to match your requirement
      });
    }
  };

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  return (
    <div className="profile-container">
      <label htmlFor="file-input">
        <div className="profile-image-wrapper">
          <img src={localProfileImage} alt="Profile" className="profile-image-large" />
        </div>
      </label>
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
      {upImg && (
        <div>
          <ReactCrop
            crop={crop}
            ruleOfThirds
            onComplete={(c) => onCropComplete(c as PixelCrop)}
            onChange={(c) => onCropChange(c as Crop, c as unknown as PercentCrop)}
            aspect={1 / 1}
          >
            <img
              ref={imageRef}
              alt="Crop me"
              src={upImg}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <button onClick={handleSaveCrop} className="profile-button">Speichern</button>
        </div>
      )}
      <h2>{firstName} {lastName}</h2>
      <div className="profile-info">
        <label>E-Mail</label>
        <p>{user.email}</p>
      </div>
      <div className="profile-info">
        <label>Kontoart</label>
        <div className="dropdown-container">
          <select value={accountType} onChange={handleKontoArtChange}>
            <option value="Privat">Privat</option>
            <option value="Gewerblich">Gewerblich</option>
          </select>
        </div>
      </div>
      <div className="button-container">
        {accountType === "Gewerblich" && (
          <button
            className="profile-button"
            onClick={() => navigate('/impressum')}
          >
            Impressum & Foto hinzufügen
          </button>
        )}
        <button className="profile-button" onClick={() => navigate('/feedback')}>Feedback</button>
        <button className="profile-button" onClick={handleFAQClick}>FAQ</button>
        <button className="profile-button" onClick={() => navigate('/support')}>Support</button>
        <button className="logout-button" onClick={handleSignOut}>Logout</button>
        <button className="profile-delete-button" onClick={handleDeleteAccount}>Konto löschen</button>
      </div>
    </div>
  );
};

export default Profile;
