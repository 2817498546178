import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../firebaseConfig'; // Stelle sicher, dass du hier die richtige Firebase-Konfiguration importierst
import './UploadImages.css'; // Verwende das bestehende CSS für Styling

const ImportPage: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    if (file) {
      const storageRef = ref(storage, `gs://automatch-dev-import/${file.name}`);
      
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        }, 
        (error) => {
          console.error('Upload failed:', error);
        }, 
        () => {
          console.log('Upload successful!');
        }
      );
    }
  };

  return (
    <div className="upload-images-container">
      <h2>Importiere deine Fahrzeugdaten</h2>
      <div className="file-input-container">
        <input 
          type="file" 
          id="file-input" 
          className="file-input" 
          onChange={handleFileChange} 
        />
        <label htmlFor="file-input" className="file-input-label">
          Datei auswählen
        </label>
        {fileName && (
          <div className="file-input-filename">
            <span className="file-name">{fileName}</span>
          </div>
        )}
      </div>
      <button onClick={handleUpload} className="upload-button">Datei hochladen</button>
      
      {uploadProgress > 0 && (
        <div className="progress-container">
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div 
                className="progress-bar-fill" 
                style={{ width: `${uploadProgress}%` }} 
              />
            </div>
            <span>{Math.round(uploadProgress)}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportPage;
