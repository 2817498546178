// src/components/Register.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { auth, db, requestPermission } from '../firebaseConfig';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import './Register.css';
import { toast } from 'react-toastify';

const Register: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwörter stimmen nicht überein');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });

      await sendEmailVerification(user);
      toast.success('Verifizierungs-E-Mail wurde gesendet! Bitte überprüfen Sie Ihr Postfach.');

      // const token = await requestPermission();

      const userSnapshot = await getDoc(doc(db, 'users', user.uid));
      if (userSnapshot.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
          // token,
          lastSeen: Date.now(),
        }, { merge: true });
        navigate('/');
      } else {
        await setDoc(doc(db, 'users', user.uid), {
          firstName,
          lastName,
          email: user.email,
          uid: user.uid,
          createdAt: Timestamp.now().toMillis(),
          changedAt: Timestamp.now().toMillis(),
          role: 'SELLER',
          sellerPhotoURL: user.photoURL ?? '',
          buyerPhotoURL: user.photoURL ?? '',
          lastSeen: Timestamp.now().toMillis(),
          chattingWith: null,
          // token,
          accountType: 'PRIVATE',
        });
        navigate('/');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      toast.error('Fehler bei der Registrierung. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <div className="register-container">
      <h2>Registrierung</h2>
      <form onSubmit={handleRegister} className="register-form">
        <div className="form-group">
          <label htmlFor="firstName">Vorname</label>
          <input 
            type="text" 
            id="firstName" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Nachname</label>
          <input 
            type="text" 
            id="lastName" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">E-Mail</label>
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Passwort</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Passwort bestätigen</label>
          <input 
            type="password" 
            id="confirmPassword" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="confirm-button">Bestätigen</button>
      </form>
    </div>
  );
};

export default Register;
