import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, collection, query, getDocs, deleteDoc, updateDoc, orderBy } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './Images.css';
import { User } from 'firebase/auth';

interface Image {
  id: string;
  url: string;
  thumbUrl: string;
  imageName: string;
  orderNumber: number;
}

interface ImagesProps {
  user: User | null;
}

const Images: React.FC<ImagesProps> = ({ user }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [images, setImages] = useState<Image[]>([]);
  const [dragAndDropMode, setDragAndDropMode] = useState(false);
  const [dragImages, setDragImages] = useState<Image[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      if (id) {
        const imagesCollection = collection(db, 'properties', id, 'imageURLs');
        const imagesQuery = query(imagesCollection, orderBy('orderNumber'));
        const querySnapshot = await getDocs(imagesQuery);
        const imagesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Image));
        setImages(imagesList);
        setDragImages(imagesList); // Set dragImages initially to the same value as images
      }
    };

    fetchImages();
  }, [id]);

  const handleDeleteImage = async (imageId: string) => {
    if (id) {
      await deleteDoc(doc(db, 'properties', id, 'imageURLs', imageId));
      const updatedImages = images.filter(image => image.id !== imageId).map((image, index) => ({
        ...image,
        orderNumber: index,
      }));

      for (const image of updatedImages) {
        const imageDoc = doc(db, 'properties', id, 'imageURLs', image.id);
        await updateDoc(imageDoc, { orderNumber: image.orderNumber });
      }

      setImages(updatedImages);
      setDragImages(updatedImages); // Keep dragImages in sync with images

      // Update mainImageURL if the main photo has changed
      const mainImageDoc = doc(db, 'properties', id);
      await updateDoc(mainImageDoc, { mainImageURL: updatedImages[0]?.url || '' });
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(dragImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newOrder = items.map((image, index) => ({
      ...image,
      orderNumber: index,
    }));

    setDragImages(newOrder);
    setHasChanges(true); // Mark changes as true
  };

  const handleSaveOrder = async () => {
    if (id) {
      for (const image of dragImages) {
        const imageDoc = doc(db, 'properties', id, 'imageURLs', image.id);
        await updateDoc(imageDoc, { orderNumber: image.orderNumber });
      }

      // Update mainImageURL if the main photo has changed
      const mainImageDoc = doc(db, 'properties', id);
      await updateDoc(mainImageDoc, { mainImageURL: dragImages[0]?.url || '' });

      // Update the main images state and exit drag-and-drop mode
      setImages(dragImages);
      setDragAndDropMode(false);
      setHasChanges(false); // Reset changes flag
    }
  };

  const renderImageLabel = (orderNumber: number) => {
    if (orderNumber === 0) {
      return <span className="main-photo-label">Hauptfoto</span>;
    }
    return null;
  };

  const toggleDragAndDropMode = () => {
    setDragAndDropMode(!dragAndDropMode);
    if (dragAndDropMode) {
      // Reset dragImages to the original images state when exiting drag-and-drop mode without saving
      setDragImages(images);
      setHasChanges(false); // Reset changes flag
    }
  };

  if (!user) {
    return <div>Bitte melden Sie sich an, um Bilder zu sehen.</div>;
  }

  if (!images.length) {
    return (
      <div className="images-container">
        <p className="info-text">Keine Bilder vorhanden.</p>
        <button onClick={() => navigate(`/upload-images/${id}/0`)} className="images-upload-button">Bilder hochladen</button>
      </div>
    );
  }

  return (
    <div className="images-container">
      <h2>Bilder</h2>
      <button onClick={toggleDragAndDropMode} className={`button mode-toggle-button ${dragAndDropMode ? 'active' : ''}`}>
        {dragAndDropMode ? 'Editieren Abbrechen' : 'Reihenfolge editieren'}
      </button>
      <button onClick={() => navigate(`/upload-images/${id}/${images.length}`)} className="button upload-button">Bilder hochladen</button>
      {dragAndDropMode ? (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
              {(provided) => (
                <div className="images-horizontal" {...provided.droppableProps} ref={provided.innerRef}>
                  {dragImages.map((image, index) => (
                    <Draggable key={image.id} draggableId={image.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="image-item-horizontal"
                        >
                          {renderImageLabel(image.orderNumber)}
                          <img src={image.thumbUrl} alt={image.imageName} className="thumbnail" />
                          <button className="delete-button" onClick={() => handleDeleteImage(image.id)}>x</button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {hasChanges && (
            <button onClick={handleSaveOrder} className="button save-order-button">Änderungen speichern</button>
          )}
        </>
      ) : (
        <div className="images-grid">
          {images.map((image, index) => (
            <div key={image.id} className="image-item">
              {renderImageLabel(image.orderNumber)}
              <img src={image.thumbUrl} alt={image.imageName} className="thumbnail" />
              <button className="delete-button" onClick={() => handleDeleteImage(image.id)}>x</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Images;
