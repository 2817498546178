import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import './DetailView.css';
import { User } from 'firebase/auth';
import { carBrandModelsMap } from '../data/carBrandModelsMap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddressSearch from './AddressSearch'; // Import AddressSearch component
import DropdownField from './DropdownField';
import DropdownFieldInt from './DropdownFieldInt';
import { options } from './VehicleOptions';
import { PropertyVehicleState } from './PropertyVehicleState';

export interface Property {
  id: string;
  type: string;
  model: string;
  price: number;
  city: string;
  state: string;
  userUID: string;
  title: string;
  mainImageURL?: string;
  year: number;
  month: number;
  performance: number;
  mileage: number;
  usedCar: boolean; // Gebrauchtwagen
  radar: boolean; // Radar

  // Sicherheit
  airbag: boolean;
  abs: boolean;
  esp: boolean;
  isofix: boolean;
  isofixPassengerSeat: boolean;
  emergencyBrake: boolean; // Notbremsassistent
  hillStartAssist: boolean; // Berganfahrassistent
  blindSpotAssist: boolean; // Totwinkelassistent
  laneKeepAssist: boolean;
  rearViewCamera: boolean;
  parkingAssist: boolean;
  fatigueDetection: boolean;
  nightVisionAssist: boolean;
  intersectionAssist: boolean;
  trafficSignRecognition: boolean;
  adaptiveHeadlights: boolean;
  electronicStabilityProgram: boolean; // Elektronisches Stabilitätsprogramm (ESP)
  immobilizer: boolean; // Wegfahrsperre
  electronicBrakeSystem: boolean; // Elektronisches Bremssystem (EBS)
  brakingSystemSupport: boolean; // Bremsassistent (BSS)
  tractionControl: boolean; // Traktionskontrolle
  corneringLight: boolean; // Kurvenlicht
  fogLights: boolean; // Nebelscheinwerfer
  adaptiveCruiseControl: boolean; // Abstandstempomat
  distanceWarning: boolean; // Abstandswarner
  laneChangeAssistant: boolean; // Spurwechselassistent
  alarmSystem: boolean; // Alarmanlage
  autoDimmingInteriorMirror: boolean; // Innenspiegel automatisch abblendend
  vin: string;
  vat: number;
  numberOfDoors: number;
  emissionSticker: string;
  driveType: string;
  grossWeight: number;
  yearOfManufacture: number;
  co2Emission: number;
  emissionStandard: string;
  qualitySeal: string;
  numberOfAxles: number;
  hydraulicSystem: string;
  wheelFormula: string;
  airbagExtended: string;
  previousOwners: number;
  targetGroup: string;
  emissionClassDetail: string;
  co2EmissionWltp: number;


  // Komfort
  airConditioning: boolean;
  cruiseControl: boolean;
  seatHeatingCooling: boolean;
  electricWindows: boolean;
  electricSeatAdjustment: boolean;
  keylessGo: boolean;
  centralLocking: boolean;
  panoramicGlassRoof: boolean;
  leatherUpholstery: boolean;
  steeringWheelHeating: boolean;
  massageSeats: boolean;
  ambientLighting: boolean;
  twoZoneClimateControl: boolean;
  threeZoneClimateControl: boolean;
  fourZoneClimateControl: boolean;
  fiveZoneClimateControl: boolean;
  electricSunshadeRear: boolean;
  headUpDisplay: boolean;
  powerSteering: boolean; // Servolenkung
  sunroof: boolean; // Schiebedach
  rearSeatHeating: boolean; // Sitzheizung hinten
  rearElectricSeatAdjustment: boolean; // Elektrische Sitzeinstellung hinten
  smokerPackage: boolean; // Raucherpaket
  foldablePassengerSeat: boolean; // Umklappbarer Beifahrersitz
  centerArmrest: boolean; // Mittelarmlehne
  electricTailgate: boolean; // Elektrische Heckklappe
  adjustableSteeringWheel: boolean; // Lenkrad höhenverstellbar
  heightAdjustableDriverSeat: boolean; // Fahrersitz Höhenverstellbar
  foldableRearSeat: boolean; // Rückbank get.umleg.
  softClose: boolean; // Softclose
  climateControl: string;
  color: string;
  seatingCapacity: number;
  sleepingPlaces: number;
  vehicleLength: number;
  vehicleWidth: number;
  vehicleHeight: number;
  loadingAreaPallets: number;
  loadingAreaVolume: number;
  interiorColor: string;
  interiorType: string;
  cabinType: string; // Kabinenart

  // Infotainment und Navigation
  touchscreenSystem: boolean;
  realtimeNavigationSystem: boolean;
  bluetoothConnection: boolean;
  carPlayAndroidAuto: boolean;
  soundSystem: boolean;
  voiceControl: boolean;
  usbAux: boolean;
  dabRadio: boolean;
  wirelessCharging: boolean;
  wifiHotspot: boolean;
  arNavigation: boolean;
  onboardAppsServices: boolean;
  gestureControlMultimedia: boolean;
  digitalRadio: boolean;
  onBoardComputer: boolean; // Bordcomputer
  cdPlayer: boolean; // CD-Spieler
  handsFreeSystem: boolean; // Freisprecheinrichtung
  mp3Interface: boolean; // MP3-Schnittstelle
  audioAuxInput: boolean; // Audio-Aux-Anschluss
  iPadPodConnection: boolean; // iPad/iPod-Anschluss
  fullyDigitalInstrumentCluster: boolean; // Volldigitales Kombiinstrument
  integratedMusicStreaming: boolean; // Musikstreaming integriert
  wirelessChargingForSmartphones: boolean; // Induktionsladen Smartphones
  wifi: boolean; // WLAN
  schwackeCode: number;
  imageId: string;
  videoUrl: string;
  highlight1: string;
  highlight2: string;
  highlight3: string;
  twinnerWidget: string;

  // Exterieur und Design
  ledXenonHeadlights: boolean;
  daytimeRunningLights: boolean;
  alloyWheels: boolean;
  electricFoldingMirrors: boolean;
  roofRails: boolean;
  metallicPaint: boolean;
  panoramicSlidingRoof: boolean;
  dynamicIndicators: boolean;
  privacyGlazing: boolean;
  rearDiffuser: boolean;
  coloredBrakeCalipers: boolean;
  rightSlidingDoor: boolean; // Rechte Schiebetür
  leftSlidingDoor: boolean; // Linke Schiebetür
  doubleSlidingDoors: boolean; // Schiebetür beidseitig
  roofRack: boolean; // Dachträger
  blackOptics: boolean; // Optikschwarz
  // TODO check this VALUE color: string;
  equipmentLine: string;
  constructionType: string;
  modelBase: string;
  extendedModelText: string;
  modelKey: string;
  campaignIdentifier: string;


  // Technologie und Performance
  startStopSystem: boolean;
  hybridElectricDrive: boolean;
  adaptiveSuspension: boolean;
  drivingModes: boolean;
  allWheelDrive: boolean;
  activeAerodynamics: boolean;
  launchControl: boolean;
  cylinderDeactivation: boolean;
  dynamicEngineMounts: boolean;
  activeSteering: boolean;
  airSuspension: boolean; // Luftfederung
  allWheelSteering: boolean; // Allradlenkung
  ceramicBrakes: boolean; // Keramikbremse
  sportsSuspension: boolean; // Sportfahrwerk
  sportsExhaustSystem: boolean; // Sportabgasanlage
  electricParkingBrake: boolean; // Elek. Parkbremse
  heatPump: boolean; // Wärmepumpe
  quickdropSystem: boolean; // Quickdrop-System
  rangeExtender: boolean; // Range Extender
  pluginHybrid: boolean; // Plugin Hybrid
  matrixBeamHeadlights: boolean; // Blendfreies Fernlicht (Matrix)
  highBeamAssistant: boolean; // Fernlichtassistent
  particulateFilter: boolean; // Partikelfilter
  scrSystem: boolean; // SCR-System
  displacement: number; // Hubraum (ccm)
  loadCapacity: number; // Traglast (kg)
  payloadCapacity: number; // Nutzlast (kg)
  liftHeight: number; // Hubhöhe (mm)
  constructionHeight: number; // Bauhöhe (mm)
  operatingHours: number; // Betriebsstunden
  cityFuelConsumption: number; // Verbrauch innerorts (l/100km)
  highwayFuelConsumption: number; // Verbrauch außerorts (l/100km)
  combinedFuelConsumption: number; // Verbrauch kombiniert (l/100km)
  loadingAreaLength: number; // Laderaumlänge (mm)
  loadingAreaWidth: number; // Laderaumbreite (mm)
  loadingAreaHeight: number; // Laderaumhöhe (mm)
  energyEfficiencyClass: string; // Energieeffizienzklasse
  petrolType: string; // Kraftstoffsorte
  combinedElectricConsumption: number; // Kombinierter Stromverbrauch (kWh/100km)
  totalMileage: number; // Laufleistung (km)
  countryVersion: string; // Landesversion
  productionDate: string; // Produktionsdatum (should be in 'YYYY-MM-DD' format)
  emptyWeight: number; // Leergewicht (kg)
  combinedWltpFuelConsumption: number; // WLTP Kraftstoffverbrauch kombiniert (l/100km)
  wltpCityFuelConsumption: number; // WLTP Kraftstoffverbrauch Innenstadt (l/100km)
  wltpSuburbanFuelConsumption: number; // WLTP Kraftstoffverbrauch Stadtrand (l/100km)
  wltpHighwayFuelConsumption: number; // WLTP Kraftstoffverbrauch Landstraße (l/100km)
  wltpAutobahnFuelConsumption: number; // WLTP Kraftstoffverbrauch Autobahn (l/100km)
  schwackeWenr: string; // Schwacke WENR
  datSd3FileNumber: string; // DAT SD3 Aktennummer

  // Reifen und Räder
  allSeasonTires: boolean; // Allwetterreifen
  summerTires: boolean; // Sommerreifen
  winterTires: boolean; // Winterreifen
  spareWheel: boolean; // Notrad
  tireSealant: boolean; // Pannenkitt
  spareTire: boolean; // Reserverad
  steelRims: boolean; // Stahlfelge
  supplierTradeName: '', // Handelsname Lieferant
  tireTypeDesignation: '', // Reifentypkennung
  tireLabelDesignation: '', // Reifenbezeichnung
  loadIndex: 0, // Tragfähigkeitsindex
  speedIndex: '', // Geschwindigkeitsindex
  fuelEfficiencyClass: '', // Kraftstoffeffizienzklasse
  wetGripClass: '', // Nasshaftungsklasse
  externalRollingNoiseClass: '', // Externe Rollgeräuschklasse
  externalRollingNoiseValue: 0, // Wert externe Rollgeräusch (dB)
  tireProductionStartDate: '', // Herstellungsbeginn Reifentyp (should be in 'YYYY-MM-DD' format)
  tireProductionEndDate: '', // Herstellungsende Reifentyp (should be in 'YYYY-MM-DD' format)
  tradeName: '', // Handelsname
  tireAdditionalInfo: '', // Reifenzusatzinfo


  // Elektro
  batteryMissing: boolean; // E-Fahrzeug-Batterie fehlt
  batteryPurchased: boolean; // E-Fahrzeug-Batterie gekauft
  batteryRented: boolean; // E-Fahrzeug-Batterie gemietet
  acChargingPower: number; // Ladeleistung AC (kW)
  dcChargingPower: number; // Ladeleistung DC (kW)
  euTireRegistrationNumberFront: string, // EU-Reifenregisternummer (vorne)
  keyBoardNumber: string; // Schlüsselbrettnummer
  warrantyMonths: number; // Garantie Monate
  activeHeadrest: string; // AktivKopfstütze
  autonomousDriving: string; // autonomes Fahren
  modelSeries: string; // Modellreihe
  financingProduct: string; // Finanzierungsprodukt
  leasingProduct: string; // Leasingprodukt
  maxMotorPower: number; // Motorleistung (max) (kW)
  batteryVoltage: number; // Batteriespannung (Volts)
  batteryCapacity: number; // Batteriekapazität (kWh)
  batteryType: string; // Batteriebauart
  chargingPlug: string; // Ladestromstecker
  standardChargingVoltage: number; // Standardladung (Volts)
  standardChargingDuration: string; // Ladedauer bei Standardladung (AC) (Wallbox/Ladestation)
  fastChargingVoltage: number; // Schnellladung (Volts)
  fastChargingDuration: string; // Ladedauer bei Schnellladung (DC) (Wallbox/Ladestation)
  wltpElectricRange: number; // Elektromotor-Reichweite nach WLTP (km)
  wltpInnerCityElectricRange: number; // Elektromotor-Reichweite innerorts nach WLTP (km)
  totalWltpRange: number; // Gesamtreichweite nach WLTP (km)
  wltpInnerCityTotalRange: number, // Gesamtreichweite innerorts nach WLTP (km)
  mobileAdId: number; // MobileAD-ID
  weightedWltpCo2Emissions: number; // Gewichtete kombinierte CO2-Emissionen WLTP (g/km)
  weightedWltpElectricConsumption: number; // Gewichteter kombinierter Stromverbrauch WLTP (kWh/100km)
  weightedWltpFuelConsumption: number; // Gewichteter kombinierter Kraftstoffverbrauch WLTP (l/100km)
  damageOrAccidentInfo: string; // Mängelhinweis/Unfallinfo
  leasingOfferInfo: string; // Hinweis Leasingangebot
  financingOfferInfo: string; // Hinweis Finanzierungsangebot
  euTireRegistrationNumberRear: string; // EU-Reifenregisternummer (hinten)

  // Abo-Daten
  subscriptionLocation: string; // Abo-Standort
  subscriptionMinAge: number; // Abo Mindestalter in Jahre
  subscriptionDeductible: number; // Abo Selbstbeteiligung (Euro)
  subscriptionDeposit: number; // Abo Kaution (Euro)
  subscriptionTerms: string; // Abo Vertragsbedingungen
  subscriptionExtraKm: number; // Abo Mehrkilometer (km)
  subscriptionAvailability: string; // Abo Verfügbarkeit
  subscriptionDeliveryDate: string; // Abo Lieferdatum
  subscriptionDeliveryTimeframeInDays: number; // Abo Lieferfrist in Tagen
  subscriptionDeliveryOptions: string; // Abo Lieferoptionen
  subscriptionDeliveryCost: number; // Abo Lieferkosten (Euro)
  subscriptionDeliveryCostMatrix: string; // Abo Lieferkosten Matrix
  subscriptionNote: string; // Abo Bemerkung
  subscriptionHighlight: string; // Abo Highlight
  subscriptionTopTags: string; // Abo Toptags
  subscriptionPaymentTerms: string; // Abo Zahlungsbedingungen
  subscriptionRate: number; // Abo Rate (Euro)
  subscriptionRateMatrix: string; // Abo Rate Matrix
  subscriptionStartFee: number; // Abo Startgebühr (Euro)
  teaserImageUrl: string; // Abo Teaser Detailseite URL
  internalIdentifier: string; // Interner Identifier
  numberOfImages: number; // Anzahl Bilder

  // Service und Wartung
  lastMajorServiceDate: string; // Letzter großer Service (Datum)
  lastMajorServiceMileage: number; // Kilometerstand letzter großer Service
  lastMinorServiceDate: string; // Letzter kleiner Service (Datum)
  lastMinorServiceMileage: number; // Kilometerstand letzter kleiner Service
  lastOilChangeDate: string; // Letzter Ölwechsel (Datum)
  lastOilChangeMileage: number; // Kilometerstand letzter Ölwechsel
  lastBrakeChangeDate: string; // Letzter Bremsenwechsel (Datum)
  lastBrakeChangeMileage: number; // Kilometerstand letzter Bremsenwechsel

  // Fahreugdaten
  topAd: boolean; // top inserat
  cylinderCount: number; // Zylinderanzahl
  modelYear: number; // Modelljahr
  previousOwnerName: string; // Vorbesitzername
  twinner360Url: string; // Twinner 360Grad URL
  versionKey: string; // Versionsschlüssel
  exteriorBaseColor: string; // Grundfarbe aussen
  gearCount: number; // Anzahl Gänge
  marketMileage: number; // Kilometerstand für Börsen (km)
  combinedElectricConsumptionWltp: number; // kombinierter Stromverbrauch WLTP (kWh/100km)
  previousUse: string; // Vornutzung
  datEuropeCode: string; // DAT Europa Code
  fuelGrade: string; // Benzinsorte

  // Weitere Fahrzeugdaten
  buyer: string; // Käufer
  internalInfo: string; // Interne Informationen
  co2ClassWltp: string; // CO2-Klasse (WLTP)
  weightedCo2ClassWltp: string; // CO2-Klasse (WLTP) gewichtet, kombiniert
  co2ClassWltpDischargedBattery: string; // CO2-Klasse (WLTP) bei entladener Batterie
  co2EmissionsWltpDischargedBattery: number; // CO2-Emissionen (WLTP) bei entladener Batterie (g/km)
  combinedFuelConsumptionWltpDischargedBattery: number; // Kombinierter Kraftstoffverbrauch (WLTP) bei entladener Batterie (l/100km, kg/100km)
  batteryStateOfHealth: number; // State of Health (SOH) in % (Batteriezustand)
  cityElectricConsumptionWltp: number; // Stromverbrauch Innenstadt WLTP (kWh/100km)
  suburbanElectricConsumptionWltp: number; // Stromverbrauch Stadtrand WLTP (kWh/100km)
  highwayElectricConsumptionWltp: number; // Stromverbrauch Landstraße WLTP (kWh/100km)
  autobahnElectricConsumptionWltp: number; // Stromverbrauch Autobahn WLTP (kWh/100km)
  cityFuelConsumptionDischargedBatteryWltp: number; // Verbrauch entladener Batterie Innenstadt WLTP (l/100km, kg/100km)
  suburbanFuelConsumptionDischargedBatteryWltp: number; // Verbrauch entladener Batterie Stadtrand WLTP (l/100km, kg/100km)
  highwayFuelConsumptionDischargedBatteryWltp: number; // Verbrauch entladener Batterie Landstraße WLTP (l/100km, kg/100km)
  autobahnFuelConsumptionDischargedBatteryWltp: number; // Verbrauch entladener Batterie Autobahn WLTP (l/100km, kg/100km)
  vehicleLabelLink: string; // Link PkwLabel
  averageCo2PricePerTon: number; // Mittlerer CO2-Preis pro Tonne (Euro)
  accumulatedAverageCo2Price: number; // Mittlerer CO2-Preis akkumuliert (Euro)
  lowCo2PricePerTon: number; // Niedriger CO2-Preis pro Tonne (Euro)
  accumulatedLowCo2Price: number; // Niedriger CO2-Preis akkumuliert (Euro)
  highCo2PricePerTon: number; // Hoher CO2-Preis pro Tonne (Euro)
  accumulatedHighCo2Price: number; // Hoher CO2-Preis akkumuliert (Euro)
  fuelPriceReference: number; // Kraftstoffpreis (Referenz) (Euro/l)
  electricityPrice: number; // Strompreis (Euro/kWh)
  energyCostYear: number; // Jahr der Energiekosten bei 15.000 km Jahresfahrleistung
  annualEnergyCost: number; // Energiekosten bei 15.000 km Jahresfahrleistung (Euro)
  vehicleTaxPerYear: number; // Kfz-Steuer p.a. (Euro)
  co2CostStartYear: number; // Zeitrahmen (von) CO2-Kosten (Jahr)
  co2CostEndYear: number; // Zeitrahmen (bis) CO2-Kosten (Jahr)
  vehicleDetailPageUrl: string; // URL KFZ-Detailseite


  // Leasing
  leasingAnnualInterestRate: number; // Effektiver Jahreszins
  leasingMonthlyRate: number; // Monatliche Rate (Euro)
  leasingDuration: number; // Laufzeit (Monate)
  leasingDownPayment: number; // Anzahlung (Euro)
  leasingFinalRate: number; // Schlussrate (Euro)
  leasingBank: string; // Anbieterbank
  leasingResidualValue: number; // Leasing-Restwert (Euro)
  extraKilometerCost: number; // Leasing-Mehrkilometer (Cent)
  underKilometerCredit: number; // Leasing-Minderkilometer (Cent)
  totalLeasingAmount: number; // Leasing-Gesamtbetrag (Euro)
  leasingNominalInterestRate: string; // Soll-Zinssatz
  leasingInterestRateType: string; // Art des Soll-Zinssatzes
  leasingConditions: string; // Bedingungen Leasingvorschlag
  leasingNetLoanAmount: number; // Nettokreditbetrag (Leasing)
  leasingResidualValueDiscount: number; // Leasing Restwertabschlag
  leasingSpecialConditions: string; // Leasing Sonderkonditionen
  leasingMatrix: string; // Leasingmatrix
  maintenancePerMonth: number; // Wartung pro Monat (Euro)
  wearAndTearPerMonth: number; // Verschleiß pro Monat (Euro)
  maintenanceAndWearCombination: string | undefined; // Wartung & Verschleiß in Kombination pro Monat
  liabilityInsurancePerMonth: number; // Haftpflicht pro Monat (Euro)
  liabilityDeductible: number; // Haftpflicht SB (Euro)
  fullCoveragePerMonth: number; // Vollkasko pro Monat (Euro)
  fullCoverageDeductible: number; // Vollkasko SB (Euro)
  oneTimeVehicleDeliveryCost: number; // KFZ-Lieferung einmalig (Euro; netto)
  vehicleTaxPerMonth: number; // KFZ-Steuer pro Monat (Euro)
  tireCostPerMonth: number; // 8-fach Bereifung pro Monat (Euro)
  tuvCostPerMonth: number; // TÜV (HU+AU) pro Monat (Euro)
  deliveryDate: string; // Lieferdatum (Auslieferung Datum)
  storageLocation: string; // Lagerplatz
  licensePlate: string; // Kennzeichen
  actionCode: string; // Aktionscode
  returnInsurancePerMonth: number; // Rückgabeversicherung pro Monat (Euro)
  oneTimeRegistrationCost: number; // Zulassungskosten einmalig (Euro; netto)
  bafaBonus: number; // Bafa Bonus (Euro)

  // Finanzierung
  annualInterestRate: number; // Effektiver Jahreszins
  monthlyRate: number; // Monatliche Rate (Euro)
  durationInMonths: number; // Laufzeit (Monate)
  downPayment: number; // Anzahlung (Euro)
  finalRate: number; // Schlussrate (Euro)
  grossLoanAmount: number; // Bruttokreditbetrag (Euro)
  closingFees: number; // Abschlussgebühren (Euro)
  installmentInsurance: number; // Ratenabsicherung (Euro)
  netLoanAmount: number; // Nettokreditbetrag (Euro)
  financingBank: string; // Anbieterbank
  nominalInterestRate: number; // Nominal Zinssatz / Soll-Zinssatz
  interestRateType: string; // Art des Soll-Zinssatzes
  financingConditions: string; // Bedingungen Finanzierungsvorschlag
  formerMsrp: number; // Ehemalige Preisempfehlung (UVP)
  financingMileage: number; // Laufleistung (Finanzierung)
  // TODO check this VALUE deliveryDate: string; // Lieferdatum (Finanzierung)


  // Andere
  towBar: boolean;
  thirdRowSeats: boolean;
  luggageCompartmentCover: boolean;
  rainSensor: boolean;
  lightSensor: boolean;
  thermalInsulatingGlass: boolean;
  heatedWindshield: boolean;
  heatedWindshieldWashers: boolean;
  gloveboxCooler: boolean;
  thermalCupholder: boolean;
  ambientFragranceSystems: boolean;
  vintageCar: boolean; // Oldtimer
  damagedVehicle: boolean; // Beschädigtes Fahrzeug
  taxi: boolean; // Taxi
  handicappedAccessible: boolean; // Behindertengerecht
  annualCar: boolean; // Jahreswagen
  newCar: boolean; // Neufahrzeug
  warrantyIncluded: boolean; // Garantie
  demonstrationVehicle: boolean; // Vorführfahrzeug
  serviceBookMaintained: boolean; // Scheckheftgepflegt
  municipal: boolean; // Kommunalfahrzeug
  crane: boolean; // Kran
  retarderOrIntarder: boolean; // Retarder/Intarder
  sleepingPlace: boolean; // Schlafplatz
  television: boolean; // TV
  toilet: boolean; // WC
  tailLift: boolean; // Ladebordwand
  splittingUp: boolean; // Trennwand
  kitchen: boolean; // Küche
  coolBox: boolean; // Kühlbox
  sleeperSeats: boolean; // Schlafsitze
  trunkCase: boolean; // Koffer
  windshield: boolean; // Scheibe
  stationaryAirConditioning: boolean; // Standklima
  bunkBed: boolean; // Etagenbett
  fixedBed: boolean; // Festbett
  rearGarage: boolean; // Heckgarage
  awning: boolean; // Markise
  separateShower: boolean; // Sep. Dusche
  solarPanel: boolean; // Solaranlage
  markedAsNew: boolean; // Inserat als 'neu' markieren
  nonSmokerVehicle: boolean; // Nichtraucherfahrzeug
  accidentVehicle: boolean; // Unfallfahrzeug
  rental: boolean; // Vermietbar
  auctionVehicle: boolean; // Auktionsfahrzeug
  customerOrder: boolean; // Kundenauftrag
  preOrder: boolean; // Vorlauf
  includedMaintenance: boolean; // Wartung enthalten
  includedWear: boolean; // Verschleiß enthalten
  deliveryIncluded: boolean; // Überführung enthalten
  registrationIncluded: boolean; // Zulassung enthalten
  vehicleDeliveryIncluded: boolean; // KFZ-Lieferung enthalten
  exclusiveHomepage: boolean; // Homepage Exklusiv
  youngtimer: boolean; // Youngtimer
  virtualVehicle: boolean; // Musterfahrzeug / Virtuelles Fahrzeug
  onlinePurchase: boolean; // Onlinekauf

  batteryCertificate: boolean; // Batteriezertifikat vorhanden
  virtualMirrors: boolean; // Virtuelle Seitenspiegel
  serviceNew: boolean; // Service neu
  navigationPreparation: boolean; // Navigationsvorbereitung
  mattColor: boolean; // Farbe matt
  rightHandDrive: boolean; // Rechtslenker
  bidirectionalCharging: boolean; // Bidirektionales Laden
  foldingRoof: boolean; // Faltdach
  parkAssist: boolean; // Ausparkassistent
  adaptiveChassis: boolean; // Adaptives Fahrwerk
  trailerAssist: boolean; // Anhängerrangierassistent
  trailerHitchPrep: boolean; // Vorbereitung Anhängerkupplung
  stoppieControl: boolean; // Stoppie-Control (Motorrad)
  wheelieControl: boolean; // Wheelie-Control (Motorrad)
  corneringABS: boolean; // Kurven-ABS (Motorrad)
  quickShifter: boolean; // Schaltautomat (Motorrad)
  blipper: boolean; // Blipper (Motorrad)
  heatedGrips: boolean; // Griffheizung (Motorrad)
  semiActiveSuspension: boolean; // Semi-aktives Fahrwerk (Motorrad)
  throttleRestriction: boolean; // Drossel (Motorrad)
  satelliteSystem: boolean; // SAT-Anlage (Wohnmobile)
  bikeRack: boolean; // Fahrradträger (Wohnmobile)
  maneuveringAssist: boolean; // Rangierhilfe (Wohnmobile)
  hotWater: boolean; // Warmwasser (Wohnmobile)
  queenBed: boolean; // Queensize Bett (Wohnmobile)
  frenchBed: boolean; // Französisches Bett (Wohnmobile)
  singleBed: boolean; // Einzelbett (Wohnmobile)
  rearDoubleBed: boolean; // Doppelbett hinten (Wohnmobile)
  frontDoubleBed: boolean; // Doppelbett vorne (Wohnmobile)
  foldingBeds: boolean; // Hubbetten (Klapp) (Wohnmobile)
  popUpRoofDoubleBed: boolean; // Aufstelldach-Doppelbett (Wohnmobile)
  convertibleSeatBed: boolean; // Sitz Umbaubett (Wohnmobile)
  lShapeSeating: boolean; // Sitzgruppe L-Form (Wohnmobile)
  barSeating: boolean; // Sitzgruppe Bar Form (Wohnmobile)
  gasHeater: boolean; // Heizung Gas (Wohnmobile)
  dieselHeater: boolean; // Heizung Diesel (Wohnmobile)
  petrolHeater: boolean; // Heizung Benzin (Wohnmobile)
  electricHeater: boolean; // Heizung Strom (Wohnmobile)
  woodHeater: boolean; // Heizung Holz (Wohnmobile)
  steeringAxle: boolean; // Lenkachse
  tintedWindows: boolean; // Colorverglasung
  neckHeater: boolean; // Nackenheizung (AIRSCARF)
  tuning: boolean; // Tuning
  washerFluidLevelIndicator: boolean; // Waschwasserstandsanzeige
  windDeflector: boolean; // Windschott (Cabrio)
  remoteCentralLocking: boolean; // Zentralverriegelung mit Funk
  differentialLock: boolean; // Differentialsperre (nur Nutzfahrzeuge)
  passengerDoubleSeat: boolean; // Beifahrer-Doppelsitz (nur Nutzfahrzeuge)
  woodFloor: boolean; // Holzboden (nur Nutzfahrzeuge)
  turntable: boolean; // Drehschemel (nur Nutzfahrzeuge)
  highLoader: boolean; // Hochlader (nur Nutzfahrzeuge)
  interiorLining: boolean; // Innenverkleidung (nur Nutzfahrzeuge)
  adjustableLoadingFloor: boolean; // Ladeboden - Variabel (nur Nutzfahrzeuge)
  boostPressureTempIndicators: boolean; // Ladedruck & Temperaturanzeigen (nur Nutzfahrzeuge)
  additionalHeadlights: boolean; // Zusatzscheinwerfer (nur Nutzfahrzeuge)
  winch: boolean; // Seilwinde (nur Nutzfahrzeuge)
  tachograph: boolean; // Tachograf (nur Nutzfahrzeuge)
  motorcycleFairing: boolean; // Verkleidung (nur Motorräder)





  customerNumberOrLocation: string; // Kundennummer/Filiale
  internalNumber: string; // Interne Nummer
  category: string; // Kategorie
  notes: string; // Bemerkung
  currency: string; // Währung
  vatRate: number; // MwSt. Satz
  huDate: string; // HU Datum
  registrationDate: string; // EZ Datum
  shipmentDate: string; // Lieferdatum
  deliveryTimeframe: string; // Lieferfrist
  transferCost: number; // Überführungskosten (Euro)
  fuelType: string; // Kraftstoffart
  transmissionType: string; // Getriebeart
  hsn: string; // HSN
  tsn: string; // TSN
  dealerPrice: number; // Händlerpreis
  acquisitionDate: string; // Hereinnahmedatum
  redPencilPrice: string; // Rotstiftpreis
  ebayExport: string; // eBay-K Export

  vehicleContactEmail: string; // KFZ-Ansprechpartner Email
  lastServiceDate: string; // Letzte Wartung Datum
  lastServiceKM: number; // Letzte Wartung KM
  towingCapacityBraked: number; // Anhängelast gebremst (kg)
  towingCapacityUnbraked: number; // Anhängelast ungebremst (kg)
  hitchLoad: number; // Stützlast AHK (kg)
  tankSize: number; // Tankgröße (Liter)
  electricSeatMemoryFunction: number; // Elektr. Sitzeinstellung Memory-Funktion
  seatHeight: number; // Sitzhöhe (mm)
  motorcycleRange: number; // Reichweite (km)
  standingHeight: number; // Stehhöhe (cm)
  lengthType: string; // Länge Typ (NFZ)
  heightType: string; // Höhe Typ (NFZ)
  wheelbaseType: string; // Radstand Typ (NFZ)
  operatingWeight: number; // Betriebsgewicht (kg)
  chargingPlugType: number; // Batterieladung Steckertyp
  batteryWarranty: number; // Batterie-Garantie (Monate)
  rimSize: number; // Felgengröße (Zoll)
  grossVehicleWeight: number; // Zulässiges Gesamtgewicht (KG)
  wheelbase: number; // Radstand (MM)
  // motorcycleFairing: boolean; // Verkleidung (Motorräder)
  hitchLoadCapacity: number; // Zuglast AHK
  trailerHitchType: number; // Anhängerkupplung-NFZ Typ
  rearDoorType: number; // Hintertür Art
  highRoofType: number; // Hochdach Art
  conditionReport: number; // Zustandsbericht

}

interface DetailViewProps {
  user: User | null;
}

const DetailView: React.FC<DetailViewProps> = ({ user }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [property, setProperty] = useState<Property | null>({
    id: '',
    type: '',
    model: '',
    price: 0,
    city: '',
    state: '',
    userUID: '',
    title: '',
    mainImageURL: '',
    year: 0,
    month: 0,
    performance: 0,
    mileage: 0,
    usedCar: false, // Gebrauchtwagen
    radar: false, // Radar
    airbag: false,
    abs: false,
    esp: false,
    isofix: false,
    isofixPassengerSeat: false,
    emergencyBrake: false,
    hillStartAssist: false,
    blindSpotAssist: false,
    laneKeepAssist: false,
    rearViewCamera: false,
    parkingAssist: false,
    fatigueDetection: false,
    nightVisionAssist: false,
    intersectionAssist: false,
    trafficSignRecognition: false,
    adaptiveHeadlights: false,
    airConditioning: false,
    cruiseControl: false,
    seatHeatingCooling: false,
    electricWindows: false,
    electricSeatAdjustment: false,
    keylessGo: false,
    centralLocking: false,
    panoramicGlassRoof: false,
    leatherUpholstery: false,
    steeringWheelHeating: false,
    massageSeats: false,
    ambientLighting: false,
    twoZoneClimateControl: false,
    threeZoneClimateControl: false,
    fourZoneClimateControl: false,
    fiveZoneClimateControl: false,
    electricSunshadeRear: false,
    headUpDisplay: false,
    touchscreenSystem: false,
    realtimeNavigationSystem: false,
    bluetoothConnection: false,
    carPlayAndroidAuto: false,
    soundSystem: false,
    voiceControl: false,
    usbAux: false,
    dabRadio: false,
    wirelessCharging: false,
    wifiHotspot: false,
    arNavigation: false,
    onboardAppsServices: false,
    gestureControlMultimedia: false,
    digitalRadio: false,
    ledXenonHeadlights: false,
    daytimeRunningLights: false,
    alloyWheels: false,
    electricFoldingMirrors: false,
    roofRails: false,
    metallicPaint: false,
    panoramicSlidingRoof: false,
    dynamicIndicators: false,
    privacyGlazing: false,
    rearDiffuser: false,
    coloredBrakeCalipers: false,
    startStopSystem: false,
    hybridElectricDrive: false,
    adaptiveSuspension: false,
    drivingModes: false,
    allWheelDrive: false,
    activeAerodynamics: false,
    launchControl: false,
    cylinderDeactivation: false,
    dynamicEngineMounts: false,
    activeSteering: false,
    towBar: false,
    thirdRowSeats: false,
    luggageCompartmentCover: false,
    rainSensor: false,
    lightSensor: false,
    thermalInsulatingGlass: false,
    heatedWindshield: false,
    heatedWindshieldWashers: false,
    gloveboxCooler: false,
    thermalCupholder: false,
    ambientFragranceSystems: false,
    electronicStabilityProgram: false,
    immobilizer: false,
    electronicBrakeSystem: false,
    brakingSystemSupport: false,
    tractionControl: false,
    corneringLight: false,
    fogLights: false,
    adaptiveCruiseControl: false,
    distanceWarning: false,
    laneChangeAssistant: false,
    alarmSystem: false,
    autoDimmingInteriorMirror: false,
    powerSteering: false,
    sunroof: false,
    rearSeatHeating: false,
    rearElectricSeatAdjustment: false,
    smokerPackage: false,
    foldablePassengerSeat: false,
    centerArmrest: false,
    electricTailgate: false,
    adjustableSteeringWheel: false,
    heightAdjustableDriverSeat: false,
    foldableRearSeat: false,
    softClose: false,
    onBoardComputer: false,
    cdPlayer: false,
    handsFreeSystem: false,
    mp3Interface: false,
    audioAuxInput: false,
    iPadPodConnection: false,
    fullyDigitalInstrumentCluster: false,
    integratedMusicStreaming: false,
    wirelessChargingForSmartphones: false,
    wifi: false,
    rightSlidingDoor: false,
    leftSlidingDoor: false,
    doubleSlidingDoors: false,
    roofRack: false,
    blackOptics: false,
    airSuspension: false,
    allWheelSteering: false,
    ceramicBrakes: false,
    sportsSuspension: false,
    sportsExhaustSystem: false,
    electricParkingBrake: false,
    heatPump: false,
    quickdropSystem: false,
    rangeExtender: false,
    pluginHybrid: false,
    matrixBeamHeadlights: false,
    highBeamAssistant: false,
    particulateFilter: false,
    scrSystem: false,
    vintageCar: false,
    damagedVehicle: false,
    taxi: false,
    handicappedAccessible: false,
    annualCar: false,
    newCar: false,
    warrantyIncluded: false,
    demonstrationVehicle: false,
    serviceBookMaintained: false,
    municipal: false,
    crane: false,
    retarderOrIntarder: false,
    sleepingPlace: false,
    television: false,
    toilet: false,
    tailLift: false,
    splittingUp: false,
    kitchen: false,
    coolBox: false,
    sleeperSeats: false,
    trunkCase: false,
    windshield: false,
    stationaryAirConditioning: false,
    bunkBed: false,
    fixedBed: false,
    rearGarage: false,
    awning: false,
    separateShower: false,
    solarPanel: false,
    markedAsNew: false,
    nonSmokerVehicle: false,
    accidentVehicle: false,
    rental: false,
    auctionVehicle: false,
    customerOrder: false,
    preOrder: false,
    includedMaintenance: false,
    includedWear: false,
    deliveryIncluded: false,
    registrationIncluded: false,
    vehicleDeliveryIncluded: false,
    exclusiveHomepage: false,
    youngtimer: false,
    virtualVehicle: false,
    onlinePurchase: false,
    allSeasonTires: false,
    summerTires: false,
    winterTires: false,
    spareWheel: false,
    tireSealant: false,
    spareTire: false,
    steelRims: false,
    batteryMissing: false,
    batteryPurchased: false,
    batteryRented: false,
    vat: 0,
    batteryCertificate: false, // Batteriezertifikat vorhanden
    virtualMirrors: false, // Virtuelle Seitenspiegel
    serviceNew: false, // Service neu
    navigationPreparation: false, // Navigationsvorbereitung
    mattColor: false, // Farbe matt
    rightHandDrive: false, // Rechtslenker
    bidirectionalCharging: false, // Bidirektionales Laden
    foldingRoof: false, // Faltdach
    parkAssist: false, // Ausparkassistent
    adaptiveChassis: false, // Adaptives Fahrwerk
    trailerAssist: false, // Anhängerrangierassistent
    trailerHitchPrep: false, // Vorbereitung Anhängerkupplung
    stoppieControl: false, // Stoppie-Control (Motorrad)
    wheelieControl: false, // Wheelie-Control (Motorrad)
    corneringABS: false, // Kurven-ABS (Motorrad)
    quickShifter: false, // Schaltautomat (Motorrad)
    blipper: false, // Blipper (Motorrad)
    heatedGrips: false, // Griffheizung (Motorrad)
    semiActiveSuspension: false, // Semi-aktives Fahrwerk (Motorrad)
    throttleRestriction: false, // Drossel (Motorrad)
    satelliteSystem: false, // SAT-Anlage (Wohnmobile)
    bikeRack: false, // Fahrradträger (Wohnmobile)
    maneuveringAssist: false, // Rangierhilfe (Wohnmobile)
    hotWater: false, // Warmwasser (Wohnmobile)
    queenBed: false, // Queensize Bett (Wohnmobile)
    frenchBed: false, // Französisches Bett (Wohnmobile)
    singleBed: false, // Einzelbett (Wohnmobile)
    rearDoubleBed: false, // Doppelbett hinten (Wohnmobile)
    frontDoubleBed: false, // Doppelbett vorne (Wohnmobile)
    foldingBeds: false, // Hubbetten (Klapp) (Wohnmobile)
    popUpRoofDoubleBed: false, // Aufstelldach-Doppelbett (Wohnmobile)
    convertibleSeatBed: false, // Sitz Umbaubett (Wohnmobile)
    lShapeSeating: false, // Sitzgruppe L-Form (Wohnmobile)
    barSeating: false, // Sitzgruppe Bar Form (Wohnmobile)
    gasHeater: false, // Heizung Gas (Wohnmobile)
    dieselHeater: false, // Heizung Diesel (Wohnmobile)
    petrolHeater: false, // Heizung Benzin (Wohnmobile)
    electricHeater: false, // Heizung Strom (Wohnmobile)
    woodHeater: false, // Heizung Holz (Wohnmobile)
    steeringAxle: false, // Lenkachse
    tintedWindows: false, // Colorverglasung
    neckHeater: false, // Nackenheizung (AIRSCARF)
    tuning: false, // Tuning
    washerFluidLevelIndicator: false, // Waschwasserstandsanzeige
    windDeflector: false, // Windschott (Cabrio)
    remoteCentralLocking: false, // Zentralverriegelung mit Funk
    differentialLock: false, // Differentialsperre (nur Nutzfahrzeuge)
    passengerDoubleSeat: false, // Beifahrer-Doppelsitz (nur Nutzfahrzeuge)
    woodFloor: false, // Holzboden (nur Nutzfahrzeuge)
    turntable: false, // Drehschemel (nur Nutzfahrzeuge)
    highLoader: false, // Hochlader (nur Nutzfahrzeuge)
    interiorLining: false, // Innenverkleidung (nur Nutzfahrzeuge)
    adjustableLoadingFloor: false, // Ladeboden - Variabel (nur Nutzfahrzeuge)
    boostPressureTempIndicators: false, // Ladedruck & Temperaturanzeigen (nur Nutzfahrzeuge)
    additionalHeadlights: false, // Zusatzscheinwerfer (nur Nutzfahrzeuge)
    winch: false, // Seilwinde (nur Nutzfahrzeuge)
    tachograph: false, // Tachograf (nur Nutzfahrzeuge)
    motorcycleFairing: false, // Verkleidung (nur Motorräder)
    topAd: false,
    vin: '',
    numberOfDoors: 4,
    emissionSticker: '',
    driveType: '',
    grossWeight: 0,
    yearOfManufacture: 2021,
    co2Emission: 0,
    emissionStandard: '',
    qualitySeal: '',
    numberOfAxles: 2,
    hydraulicSystem: '',
    wheelFormula: '',
    airbagExtended: '',
    previousOwners: 0,
    targetGroup: '',
    emissionClassDetail: '',
    co2EmissionWltp: 0,
    climateControl: '',
    color: '',
    seatingCapacity: 0,
    sleepingPlaces: 0,
    vehicleLength: 0,
    vehicleWidth: 0,
    vehicleHeight: 0,
    loadingAreaPallets: 0,
    loadingAreaVolume: 0,
    interiorColor: '',
    interiorType: '',
    cabinType: '',
    schwackeCode: 0,
    imageId: '',
    videoUrl: '',
    highlight1: '',
    highlight2: '',
    highlight3: '',
    twinnerWidget: '',
    // color: '',
    equipmentLine: '',
    constructionType: '',
    modelBase: '',
    extendedModelText: '',
    modelKey: '',
    campaignIdentifier: '',
    displacement: 0,
    loadCapacity: 0,
    payloadCapacity: 0,
    liftHeight: 0,
    constructionHeight: 0,
    operatingHours: 0,
    cityFuelConsumption: 0,
    highwayFuelConsumption: 0,
    combinedFuelConsumption: 0,
    loadingAreaLength: 0,
    loadingAreaWidth: 0,
    loadingAreaHeight: 0,
    energyEfficiencyClass: '',
    petrolType: '',
    combinedElectricConsumption: 0,
    totalMileage: 0,
    countryVersion: '',
    productionDate: '',
    emptyWeight: 0,
    combinedWltpFuelConsumption: 0,
    wltpCityFuelConsumption: 0,
    wltpSuburbanFuelConsumption: 0,
    wltpHighwayFuelConsumption: 0,
    wltpAutobahnFuelConsumption: 0,
    schwackeWenr: '',
    datSd3FileNumber: '',
    supplierTradeName: '', // Handelsname Lieferant
    tireTypeDesignation: '', // Reifentypkennung
    tireLabelDesignation: '', // Reifenbezeichnung
    loadIndex: 0, // Tragfähigkeitsindex
    speedIndex: '', // Geschwindigkeitsindex
    fuelEfficiencyClass: '', // Kraftstoffeffizienzklasse
    wetGripClass: '', // Nasshaftungsklasse
    externalRollingNoiseClass: '', // Externe Rollgeräuschklasse
    externalRollingNoiseValue: 0, // Wert externe Rollgeräusch (dB)
    tireProductionStartDate: '', // Herstellungsbeginn Reifentyp (should be in 'YYYY-MM-DD' format)
    tireProductionEndDate: '', // Herstellungsende Reifentyp (should be in 'YYYY-MM-DD' format)
    tradeName: '', // Handelsname
    tireAdditionalInfo: '', // Reifenzusatzinfo
    acChargingPower: 0, // Ladeleistung AC (kW)
    dcChargingPower: 0, // Ladeleistung DC (kW)
    euTireRegistrationNumberFront: '', // EU-Reifenregisternummer (vorne)
    keyBoardNumber: '', // Schlüsselbrettnummer
    warrantyMonths: 0, // Garantie Monate
    activeHeadrest: '', // AktivKopfstütze
    autonomousDriving: '', // autonomes Fahren
    modelSeries: '', // Modellreihe
    financingProduct: '', // Finanzierungsprodukt
    leasingProduct: '', // Leasingprodukt
    maxMotorPower: 0, // Motorleistung (max) (kW)
    batteryVoltage: 0, // Batteriespannung (Volts)
    batteryCapacity: 0, // Batteriekapazität (kWh)
    batteryType: '', // Batteriebauart
    chargingPlug: '', // Ladestromstecker
    standardChargingVoltage: 0, // Standardladung (Volts)
    standardChargingDuration: '', // Ladedauer bei Standardladung (AC) (Wallbox/Ladestation)
    fastChargingVoltage: 0, // Schnellladung (Volts)
    fastChargingDuration: '', // Ladedauer bei Schnellladung (DC) (Wallbox/Ladestation)
    wltpElectricRange: 0, // Elektromotor-Reichweite nach WLTP (km)
    wltpInnerCityElectricRange: 0, // Elektromotor-Reichweite innerorts nach WLTP (km)
    totalWltpRange: 0, // Gesamtreichweite nach WLTP (km)
    wltpInnerCityTotalRange: 0, // Gesamtreichweite innerorts nach WLTP (km)
    mobileAdId: 0, // MobileAD-ID
    weightedWltpCo2Emissions: 0, // Gewichtete kombinierte CO2-Emissionen WLTP (g/km)
    weightedWltpElectricConsumption: 0, // Gewichteter kombinierter Stromverbrauch WLTP (kWh/100km)
    weightedWltpFuelConsumption: 0, // Gewichteter kombinierter Kraftstoffverbrauch WLTP (l/100km)
    damageOrAccidentInfo: '', // Mängelhinweis/Unfallinfo
    leasingOfferInfo: '', // Hinweis Leasingangebot
    financingOfferInfo: '', // Hinweis Finanzierungsangebot
    euTireRegistrationNumberRear: '', // EU-Reifenregisternummer (hinten)
    leasingAnnualInterestRate: 0, // Effektiver Jahreszins
    leasingMonthlyRate: 0, // Monatliche Rate (Euro)
    leasingDuration: 0, // Laufzeit (Monate)
    leasingDownPayment: 0, // Anzahlung (Euro)
    leasingFinalRate: 0, // Schlussrate (Euro)
    leasingBank: '', // Anbieterbank
    leasingResidualValue: 0, // Leasing-Restwert (Euro)
    extraKilometerCost: 0, // Leasing-Mehrkilometer (Cent)
    underKilometerCredit: 0, // Leasing-Minderkilometer (Cent)
    totalLeasingAmount: 0, // Leasing-Gesamtbetrag (Euro)
    leasingNominalInterestRate: '', // Soll-Zinssatz
    leasingInterestRateType: '', // Art des Soll-Zinssatzes
    leasingConditions: '', // Bedingungen Leasingvorschlag
    leasingNetLoanAmount: 0, // Nettokreditbetrag (Leasing)
    leasingResidualValueDiscount: 0, // Leasing Restwertabschlag
    leasingSpecialConditions: '', // Leasing Sonderkonditionen
    leasingMatrix: '', // Leasingmatrix
    maintenancePerMonth: 0, // Wartung pro Monat (Euro)
    wearAndTearPerMonth: 0, // Verschleiß pro Monat (Euro)
    maintenanceAndWearCombination: undefined, // Wartung & Verschleiß in Kombination pro Monat
    liabilityInsurancePerMonth: 0, // Haftpflicht pro Monat (Euro)
    liabilityDeductible: 0, // Haftpflicht SB (Euro)
    fullCoveragePerMonth: 0, // Vollkasko pro Monat (Euro)
    fullCoverageDeductible: 0, // Vollkasko SB (Euro)
    oneTimeVehicleDeliveryCost: 0, // KFZ-Lieferung einmalig (Euro, netto)
    vehicleTaxPerMonth: 0, // KFZ-Steuer pro Monat (Euro)
    tireCostPerMonth: 0, // 8-fach Bereifung pro Monat (Euro)
    tuvCostPerMonth: 0, // TÜV (HU+AU) pro Monat (Euro)
    deliveryDate: '', // Lieferdatum (Auslieferung Datum)
    storageLocation: '', // Lagerplatz
    licensePlate: '', // Kennzeichen
    actionCode: '', // Aktionscode
    returnInsurancePerMonth: 0, // Rückgabeversicherung pro Monat (Euro)
    oneTimeRegistrationCost: 0, // Zulassungskosten einmalig (Euro, netto)
    bafaBonus: 0, // Bafa Bonus (Euro)
    annualInterestRate: 0, // Effektiver Jahreszins
    monthlyRate: 0, // Monatliche Rate (Euro)
    durationInMonths: 0, // Laufzeit (Monate)
    downPayment: 0, // Anzahlung (Euro)
    finalRate: 0, // Schlussrate (Euro)
    grossLoanAmount: 0, // Bruttokreditbetrag (Euro)
    closingFees: 0, // Abschlussgebühren (Euro)
    installmentInsurance: 0, // Ratenabsicherung (Euro)
    netLoanAmount: 0, // Nettokreditbetrag (Euro)
    financingBank: '', // Anbieterbank
    nominalInterestRate: 0, // Nominal Zinssatz / Soll-Zinssatz
    interestRateType: '', // Art des Soll-Zinssatzes
    financingConditions: '', // Bedingungen Finanzierungsvorschlag
    formerMsrp: 0, // Ehemalige Preisempfehlung (UVP)
    financingMileage: 0, // Laufleistung (Finanzierung)
    // TODO check this VALUE deliveryDate: '', // Lieferdatum (Finanzierung)
    customerNumberOrLocation: '', // Kundennummer/Filiale
    internalNumber: '', // Interne Nummer
    category: '', // Kategorie
    notes: '', // Bemerkung
    currency: '', // Währung
    vatRate: 0, // MwSt. Satz
    huDate: '', // HU Datum
    registrationDate: '', // EZ Datum
    shipmentDate: '', // Lieferdatum
    deliveryTimeframe: '', // Lieferfrist
    transferCost: 0, // Überführungskosten (Euro)
    fuelType: '', // Kraftstoffart
    transmissionType: '', // Getriebeart
    hsn: '', // HSN
    tsn: '', // TSN
    dealerPrice: 0, // Händlerpreis
    acquisitionDate: '', // Hereinnahmedatum
    redPencilPrice: '', // Rotstiftpreis
    ebayExport: '', // eBay-K Export
    subscriptionLocation: '', // Abo-Standort
    subscriptionMinAge: 0, // Abo Mindestalter in Jahre
    subscriptionDeductible: 0, // Abo Selbstbeteiligung (Euro)
    subscriptionDeposit: 0, // Abo Kaution (Euro)
    subscriptionTerms: '', // Abo Vertragsbedingungen
    subscriptionExtraKm: 0, // Abo Mehrkilometer (km)
    subscriptionAvailability: '', // Abo Verfügbarkeit
    subscriptionDeliveryDate: '', // Abo Lieferdatum
    subscriptionDeliveryTimeframeInDays: 0, // Abo Lieferfrist in Tagen
    subscriptionDeliveryOptions: '', // Abo Lieferoptionen
    subscriptionDeliveryCost: 0, // Abo Lieferkosten (Euro)
    subscriptionDeliveryCostMatrix: '', // Abo Lieferkosten Matrix
    subscriptionNote: '', // Abo Bemerkung
    subscriptionHighlight: '', // Abo Highlight
    subscriptionTopTags: '', // Abo Toptags
    subscriptionPaymentTerms: '', // Abo Zahlungsbedingungen
    subscriptionRate: 0, // Abo Rate (Euro)
    subscriptionRateMatrix: '', // Abo Rate Matrix
    subscriptionStartFee: 0, // Abo Startgebühr (Euro)
    teaserImageUrl: '', // Abo Teaser Detailseite URL
    internalIdentifier: '', // Interner Identifier
    numberOfImages: 0, // Anzahl Bilder
    lastMajorServiceDate: '', // Letzter großer Service (Datum)
    lastMajorServiceMileage: 0, // Kilometerstand letzter großer Service
    lastMinorServiceDate: '', // Letzter kleiner Service (Datum)
    lastMinorServiceMileage: 0, // Kilometerstand letzter kleiner Service
    lastOilChangeDate: '', // Letzter Ölwechsel (Datum)
    lastOilChangeMileage: 0, // Kilometerstand letzter Ölwechsel
    lastBrakeChangeDate: '', // Letzter Bremsenwechsel (Datum)
    lastBrakeChangeMileage: 0, // Kilometerstand letzter Bremsenwechsel
    cylinderCount: 0, // Zylinderanzahl
    modelYear: 0, // Modelljahr
    previousOwnerName: '', // Vorbesitzername
    twinner360Url: '', // Twinner 360Grad URL
    versionKey: '', // Versionsschlüssel
    exteriorBaseColor: '', // Grundfarbe aussen
    gearCount: 0, // Anzahl Gänge
    marketMileage: 0, // Kilometerstand für Börsen (km)
    combinedElectricConsumptionWltp: 0, // kombinierter Stromverbrauch WLTP (kWh/100km)
    previousUse: '', // Vornutzung
    datEuropeCode: '', // DAT Europa Code
    fuelGrade: '', // Kraftstoffqualität
    buyer: '', // Käufer
    internalInfo: '', // Interne Informationen
    co2ClassWltp: '', // CO2-Klasse (WLTP)
    weightedCo2ClassWltp: '', // CO2-Klasse (WLTP) gewichtet, kombiniert
    co2ClassWltpDischargedBattery: '', // CO2-Klasse (WLTP) bei entladener Batterie
    co2EmissionsWltpDischargedBattery: 0, // CO2-Emissionen (WLTP) bei entladener Batterie (g/km)
    combinedFuelConsumptionWltpDischargedBattery: 0, // Kombinierter Kraftstoffverbrauch (WLTP) bei entladener Batterie (l/100km, kg/100km)
    batteryStateOfHealth: 0, // State of Health (SOH) in % (Batteriezustand)
    cityElectricConsumptionWltp: 0, // Stromverbrauch Innenstadt WLTP (kWh/100km)
    suburbanElectricConsumptionWltp: 0, // Stromverbrauch Stadtrand WLTP (kWh/100km)
    highwayElectricConsumptionWltp: 0, // Stromverbrauch Landstraße WLTP (kWh/100km)
    autobahnElectricConsumptionWltp: 0, // Stromverbrauch Autobahn WLTP (kWh/100km)
    cityFuelConsumptionDischargedBatteryWltp: 0, // Verbrauch entladener Batterie Innenstadt WLTP (l/100km, kg/100km)
    suburbanFuelConsumptionDischargedBatteryWltp: 0, // Verbrauch entladener Batterie Stadtrand WLTP (l/100km, kg/100km)
    highwayFuelConsumptionDischargedBatteryWltp: 0, // Verbrauch entladener Batterie Landstraße WLTP (l/100km, kg/100km)
    autobahnFuelConsumptionDischargedBatteryWltp: 0, // Verbrauch entladener Batterie Autobahn WLTP (l/100km, kg/100km)
    vehicleLabelLink: '', // Link PkwLabel
    averageCo2PricePerTon: 0, // Mittlerer CO2-Preis pro Tonne (Euro)
    accumulatedAverageCo2Price: 0, // Mittlerer CO2-Preis akkumuliert (Euro)
    lowCo2PricePerTon: 0, // Niedriger CO2-Preis pro Tonne (Euro)
    accumulatedLowCo2Price: 0, // Niedriger CO2-Preis akkumuliert (Euro)
    highCo2PricePerTon: 0, // Hoher CO2-Preis pro Tonne (Euro)
    accumulatedHighCo2Price: 0, // Hoher CO2-Preis akkumuliert (Euro)
    fuelPriceReference: 0, // Kraftstoffpreis (Referenz) (Euro/l)
    electricityPrice: 0, // Strompreis (Euro/kWh)
    energyCostYear: 0, // Jahr der Energiekosten bei 15.000 km Jahresfahrleistung
    annualEnergyCost: 0, // Energiekosten bei 15.000 km Jahresfahrleistung (Euro)
    vehicleTaxPerYear: 0, // Kfz-Steuer p.a. (Euro)
    co2CostStartYear: 0, // Zeitrahmen (von) CO2-Kosten (Jahr)
    co2CostEndYear: 0, // Zeitrahmen (bis) CO2-Kosten (Jahr)
    vehicleDetailPageUrl: '', // URL KFZ-Detailseite
    vehicleContactEmail: '', // KFZ-Ansprechpartner Email (max. 200 Zeichen)
    lastServiceDate: '', // Letzte Wartung Datum (Format='tt.mm.jjjj')
    lastServiceKM: 0, // Letzte Wartung KM (Ganzzahl in km)
    towingCapacityBraked: 0, // Anhängelast gebremst (Ganzzahl in kg, max. 9999)
    towingCapacityUnbraked: 0, // Anhängelast ungebremst (Ganzzahl in kg, max. 9999)
    hitchLoad: 0, // Stützlast AHK (Ganzzahl in kg, max. 999)
    tankSize: 0, // Tankgröße (Ganzzahl in Liter, max. 9999)
    electricSeatMemoryFunction: 0, // Elektr. Sitzeinstellung Memory-Funktion ('0-3')
    seatHeight: 0, // Sitzhöhe (Motorrad, Ganzzahl in mm, max. 999)
    motorcycleRange: 0, // Reichweite (Motorrad, Ganzzahl in km)
    standingHeight: 0, // Stehhöhe (Wohnmobile, Ganzzahl in cm, max. 299)
    lengthType: '', // Länge Typ (NFZ, z.B. L1, L2, L3, L4, L5)
    heightType: '', // Höhe Typ (NFZ, z.B. H1, H2, H3)
    wheelbaseType: '', // Radstand Typ (z.B. SHORT, MIDDLE, LONG, EXTRA_LONG)
    operatingWeight: 0, // Betriebsgewicht (Ganzzahl in kg, max. 999.999)
    chargingPlugType: 0, // Batterieladung Steckertyp ('0-8')
    batteryWarranty: 0, // Batterie-Garantie (Ganzzahl in Monaten)
    rimSize: 0, // Felgengröße (Ganzzahl in Zoll)
    grossVehicleWeight: 0, // Zulässiges Gesamtgewicht (Ganzzahl in KG)
    wheelbase: 0, // Radstand (Ganzzahl in MM)
    // motorcycleFairing: false, // Verkleidung (nur Motorräder, '0'=nein, '1'=ja)
    hitchLoadCapacity: 0, // Zuglast AHK ('0-4')
    trailerHitchType: 0, // Anhängerkupplung-NFZ Typ ('0-2')
    rearDoorType: 0, // Hintertür Art ('0-6')
    highRoofType: 0, // Hochdach Art ('0-2')
    conditionReport: 0 // Zustandsbericht ('0-5')


  });

  const [editMode, setEditMode] = useState(false);
  const [models, setModels] = useState<string[]>(['Alle Modelle']);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [isValidAddress, setIsValidAddress] = useState(true);
  const [addressFeedback, setAddressFeedback] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const years = Array.from(new Array(new Date().getFullYear() - 1899), (x, i) => (1900 + i).toString()).reverse();

  useEffect(() => {
    const fetchProperty = async () => {
      if (id) {
        const docRef = doc(db, 'properties', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const propertyData = { id: docSnap.id, ...docSnap.data() } as Property;
          setProperty(propertyData);
          console.log('Property:', propertyData);
          setModels(carBrandModelsMap[propertyData.type] || ['Alle Modelle']);
        } else {
          console.error('No such document!');
        }
      }
    };

    fetchProperty();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLInputElement>) => {
    if (property) {
      const { name, value } = e.target;
      const updatedProperty = {
        ...property,
        [name]: name === 'price' || name === 'year' || name === 'month' || name === 'performance' || name === 'mileage' ? Number(value) : value,
      };
      if (name === 'type') {
        setModels(carBrandModelsMap[value] || ['Alle Modelle']);
        updatedProperty.model = 'Alle Modelle';
      }
      setProperty(updatedProperty);
    }
  };

  const handleVariableChangeDropdown = (name: keyof Property, value: string) => {
    setProperty((prevProperty) => {
      if (!prevProperty) return null; // Handle case where prevProperty is null

      return {
        ...prevProperty,
        [name]: value,
      };
    });
  };

  const handleVariableChangeDropdownInt = (name: keyof Property, value: number) => {
    setProperty((prevProperty) => {
      if (!prevProperty) return null; // Handle case where prevProperty is null

      return {
        ...prevProperty,
        [name]: value,
      };
    });
  };

  const handleVariableChange = (name: keyof Property, value: string | number) => {
    setProperty((prevProperty) => {
      if (!prevProperty) return null; // Handle case where prevProperty is null

      return {
        ...prevProperty,
        [name]: value,
      };
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (property) {
      const { name, checked } = e.target;
      setProperty({
        ...property,
        [name]: checked,
      });
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault(); // Verhindert das Neuladen der Seite
    if (!isValidAddress) {
      setAddressFeedback('Bitte wählen Sie einen gültigen Ort aus der Liste aus.');
      return;
    }
    if (property) {
      console.log('Saving property:', property);
      try {
        const docRef = doc(db, 'properties', property.id);
        await updateDoc(docRef, {
          ...property,
          type: property.type,
          model: property.model,
          price: property.price,
          city: property.city ?? '',
          state: property.state ?? '',
          title: property.title,
          mainImageURL: property.mainImageURL ?? '',
          year: property.year,
          month: property.month,
          performance: property.performance,
          mileage: property.mileage,
          usedCar: property.usedCar,
          radar: property.radar,
          airbag: property.airbag ?? false,
          abs: property.abs ?? false,
          esp: property.esp ?? false,
          isofix: property.isofix ?? false,
          isofixPassengerSeat: property.isofixPassengerSeat ?? false,
          emergencyBrake: property.emergencyBrake ?? false,
          hillStartAssist: property.hillStartAssist ?? false,
          blindSpotAssist: property.blindSpotAssist ?? false,
          laneKeepAssist: property.laneKeepAssist ?? false,
          rearViewCamera: property.rearViewCamera ?? false,
          parkingAssist: property.parkingAssist ?? false,
          fatigueDetection: property.fatigueDetection ?? false,
          nightVisionAssist: property.nightVisionAssist ?? false,
          intersectionAssist: property.intersectionAssist ?? false,
          trafficSignRecognition: property.trafficSignRecognition ?? false,
          adaptiveHeadlights: property.adaptiveHeadlights ?? false,
          airConditioning: property.airConditioning ?? false,
          cruiseControl: property.cruiseControl ?? false,
          seatHeatingCooling: property.seatHeatingCooling ?? false,
          electricWindows: property.electricWindows ?? false,
          electricSeatAdjustment: property.electricSeatAdjustment ?? false,
          keylessGo: property.keylessGo ?? false,
          centralLocking: property.centralLocking ?? false,
          panoramicGlassRoof: property.panoramicGlassRoof ?? false,
          leatherUpholstery: property.leatherUpholstery ?? false,
          steeringWheelHeating: property.steeringWheelHeating ?? false,
          massageSeats: property.massageSeats ?? false,
          ambientLighting: property.ambientLighting ?? false,
          twoZoneClimateControl: property.twoZoneClimateControl ?? false,
          threeZoneClimateControl: property.threeZoneClimateControl ?? false,
          fourZoneClimateControl: property.fourZoneClimateControl ?? false,
          fiveZoneClimateControl: property.fiveZoneClimateControl ?? false,
          electricSunshadeRear: property.electricSunshadeRear ?? false,
          headUpDisplay: property.headUpDisplay ?? false,
          touchscreenSystem: property.touchscreenSystem ?? false,
          realtimeNavigationSystem: property.realtimeNavigationSystem ?? false,
          bluetoothConnection: property.bluetoothConnection ?? false,
          carPlayAndroidAuto: property.carPlayAndroidAuto ?? false,
          soundSystem: property.soundSystem ?? false,
          voiceControl: property.voiceControl ?? false,
          usbAux: property.usbAux ?? false,
          dabRadio: property.dabRadio ?? false,
          wirelessCharging: property.wirelessCharging ?? false,
          wifiHotspot: property.wifiHotspot ?? false,
          arNavigation: property.arNavigation ?? false,
          onboardAppsServices: property.onboardAppsServices ?? false,
          gestureControlMultimedia: property.gestureControlMultimedia ?? false,
          digitalRadio: property.digitalRadio ?? false,
          ledXenonHeadlights: property.ledXenonHeadlights ?? false,
          daytimeRunningLights: property.daytimeRunningLights ?? false,
          alloyWheels: property.alloyWheels ?? false,
          electricFoldingMirrors: property.electricFoldingMirrors ?? false,
          roofRails: property.roofRails ?? false,
          metallicPaint: property.metallicPaint ?? false,
          panoramicSlidingRoof: property.panoramicSlidingRoof ?? false,
          dynamicIndicators: property.dynamicIndicators ?? false,
          privacyGlazing: property.privacyGlazing ?? false,
          rearDiffuser: property.rearDiffuser ?? false,
          coloredBrakeCalipers: property.coloredBrakeCalipers ?? false,
          startStopSystem: property.startStopSystem ?? false,
          hybridElectricDrive: property.hybridElectricDrive ?? false,
          adaptiveSuspension: property.adaptiveSuspension ?? false,
          drivingModes: property.drivingModes ?? false,
          allWheelDrive: property.allWheelDrive ?? false,
          activeAerodynamics: property.activeAerodynamics ?? false,
          launchControl: property.launchControl ?? false,
          cylinderDeactivation: property.cylinderDeactivation ?? false,
          dynamicEngineMounts: property.dynamicEngineMounts ?? false,
          activeSteering: property.activeSteering ?? false,
          towBar: property.towBar ?? false,
          thirdRowSeats: property.thirdRowSeats ?? false,
          luggageCompartmentCover: property.luggageCompartmentCover ?? false,
          rainSensor: property.rainSensor ?? false,
          lightSensor: property.lightSensor ?? false,
          thermalInsulatingGlass: property.thermalInsulatingGlass ?? false,
          heatedWindshield: property.heatedWindshield ?? false,
          heatedWindshieldWashers: property.heatedWindshieldWashers ?? false,
          gloveboxCooler: property.gloveboxCooler ?? false,
          thermalCupholder: property.thermalCupholder ?? false,
          ambientFragranceSystems: property.ambientFragranceSystems ?? false,
          electronicStabilityProgram: property.electronicStabilityProgram ?? false,
          immobilizer: property.immobilizer ?? false,
          electronicBrakeSystem: property.electronicBrakeSystem ?? false,
          brakingSystemSupport: property.brakingSystemSupport ?? false,
          tractionControl: property.tractionControl ?? false,
          corneringLight: property.corneringLight ?? false,
          fogLights: property.fogLights ?? false,
          adaptiveCruiseControl: property.adaptiveCruiseControl ?? false,
          distanceWarning: property.distanceWarning ?? false,
          laneChangeAssistant: property.laneChangeAssistant ?? false,
          alarmSystem: property.alarmSystem ?? false,
          autoDimmingInteriorMirror: property.autoDimmingInteriorMirror ?? false,
          powerSteering: property.powerSteering ?? false,
          rearSeatHeating: property.rearSeatHeating ?? false,
          rearElectricSeatAdjustment: property.rearElectricSeatAdjustment ?? false,
          smokerPackage: property.smokerPackage ?? false,
          foldablePassengerSeat: property.foldablePassengerSeat ?? false,
          centerArmrest: property.centerArmrest ?? false,
          electricTailgate: property.electricTailgate ?? false,
          adjustableSteeringWheel: property.adjustableSteeringWheel ?? false,
          heightAdjustableDriverSeat: property.heightAdjustableDriverSeat ?? false,
          foldableRearSeat: property.foldableRearSeat ?? false,
          softClose: property.softClose ?? false,
          onBoardComputer: property.onBoardComputer ?? false,
          cdPlayer: property.cdPlayer ?? false,
          handsFreeSystem: property.handsFreeSystem ?? false,
          mp3Interface: property.mp3Interface ?? false,
          audioAuxInput: property.audioAuxInput ?? false,
          iPadPodConnection: property.iPadPodConnection ?? false,
          fullyDigitalInstrumentCluster: property.fullyDigitalInstrumentCluster ?? false,
          integratedMusicStreaming: property.integratedMusicStreaming ?? false,
          wirelessChargingForSmartphones: property.wirelessChargingForSmartphones ?? false,
          wifi: property.wifi ?? false,
          rightSlidingDoor: property.rightSlidingDoor ?? false,
          leftSlidingDoor: property.leftSlidingDoor ?? false,
          doubleSlidingDoors: property.doubleSlidingDoors ?? false,
          roofRack: property.roofRack ?? false,
          blackOptics: property.blackOptics ?? false,
          airSuspension: property.airSuspension ?? false,
          allWheelSteering: property.allWheelSteering ?? false,
          ceramicBrakes: property.ceramicBrakes ?? false,
          sportsSuspension: property.sportsSuspension ?? false,
          sportsExhaustSystem: property.sportsExhaustSystem ?? false,
          electricParkingBrake: property.electricParkingBrake ?? false,
          heatPump: property.heatPump ?? false,
          quickdropSystem: property.quickdropSystem ?? false,
          rangeExtender: property.rangeExtender ?? false,
          pluginHybrid: property.pluginHybrid ?? false,
          matrixBeamHeadlights: property.matrixBeamHeadlights ?? false,
          highBeamAssistant: property.highBeamAssistant ?? false,
          particulateFilter: property.particulateFilter ?? false,
          scrSystem: property.scrSystem ?? false,
          vintageCar: property.vintageCar ?? false,
          damagedVehicle: property.damagedVehicle ?? false,
          taxi: property.taxi ?? false,
          handicappedAccessible: property.handicappedAccessible ?? false,
          annualCar: property.annualCar ?? false,
          newCar: property.newCar ?? false,
          warrantyIncluded: property.warrantyIncluded ?? false,
          demonstrationVehicle: property.demonstrationVehicle ?? false,
          serviceBookMaintained: property.serviceBookMaintained ?? false,
          municipal: property.municipal ?? false,
          crane: property.crane ?? false,
          retarderOrIntarder: property.retarderOrIntarder ?? false,
          sleepingPlace: property.sleepingPlace ?? false,
          television: property.television ?? false,
          toilet: property.toilet ?? false,
          tailLift: property.tailLift ?? false,
          splittingUp: property.splittingUp ?? false,
          kitchen: property.kitchen ?? false,
          coolBox: property.coolBox ?? false,
          sleeperSeats: property.sleeperSeats ?? false,
          trunkCase: property.trunkCase ?? false,
          windshield: property.windshield ?? false,
          stationaryAirConditioning: property.stationaryAirConditioning ?? false,
          bunkBed: property.bunkBed ?? false,
          fixedBed: property.fixedBed ?? false,
          rearGarage: property.rearGarage ?? false,
          awning: property.awning ?? false,
          separateShower: property.separateShower ?? false,
          solarPanel: property.solarPanel ?? false,
          markedAsNew: property.markedAsNew ?? false,
          nonSmokerVehicle: property.nonSmokerVehicle ?? false,
          accidentVehicle: property.accidentVehicle ?? false,
          rental: property.rental ?? false,
          auctionVehicle: property.auctionVehicle ?? false,
          customerOrder: property.customerOrder ?? false,
          preOrder: property.preOrder ?? false,
          includedMaintenance: property.includedMaintenance ?? false,
          includedWear: property.includedWear ?? false,
          deliveryIncluded: property.deliveryIncluded ?? false,
          registrationIncluded: property.registrationIncluded ?? false,
          vehicleDeliveryIncluded: property.vehicleDeliveryIncluded ?? false,
          exclusiveHomepage: property.exclusiveHomepage ?? false,
          youngtimer: property.youngtimer ?? false,
          virtualVehicle: property.virtualVehicle ?? false,
          onlinePurchase: property.onlinePurchase ?? false,
          allSeasonTires: property.allSeasonTires ?? false,
          summerTires: property.summerTires ?? false,
          winterTires: property.winterTires ?? false,
          spareWheel: property.spareWheel ?? false,
          tireSealant: property.tireSealant ?? false,
          spareTire: property.spareTire ?? false,
          steelRims: property.steelRims ?? false,
          batteryMissing: property.batteryMissing ?? false,
          batteryPurchased: property.batteryPurchased ?? false,
          batteryRented: property.batteryRented ?? false,
        });
        setEditMode(false);
        toast.success('Änderungen erfolgreich gespeichert!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.error('Error updating document: ', error);
        toast.error('Fehler beim Speichern der Änderungen!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleDelete = async () => {
    if (property) {
      try {
        const docRef = doc(db, 'properties', property.id);
        await deleteDoc(docRef);
        toast.success('Fahrzeug erfolgreich gelöscht!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/'); // Navigate back to the home page or any other page after deletion
      } catch (error) {
        toast.error('Fehler beim Löschen des Fahrzeugs!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const toggleSection = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleSelectAddress = (city: string, state: string) => {
    if (property) {
      setProperty({ ...property, city, state });
      setIsValidAddress(true);
      setAddressFeedback('');
    }
  };

  const handleAddressInput = () => {
    setIsValidAddress(false);
    setAddressFeedback('Bitte wählen Sie einen gültigen Ort aus der Liste aus.');
  };

  const renderCheckboxField = (label: string, name: string, checked: boolean = false) => (
    <label className="horizontal-label">
      <span>{label}</span>
      <input type="checkbox" id={name} name={name} checked={checked} onChange={handleCheckboxChange} />
      <span className={`horizontal-span ${checked ? '' : 'no'}`}>{checked ? "JA" : "NEIN"}</span>
    </label>
  );

  const renderEditableTextField = (label: string, name: string, value: string = '', onChange: (val: string) => void) => (
    // <div className="editable-container">
    <label className="horizontal-label">
      <span className="label-text">{label}</span>
      <input
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="editable-input"
      />
      <span className="edit-icon">
        <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
      </span>
    </label>
    // </div>
  );

  const renderEditableNumericField = (label: string, name: string, value: number | string = '', onChange: (val: number) => void) => (
    // <div className="editable-container">
    <label className="horizontal-label">
      <span className="label-text">{label}</span>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value, 10) || 0)}
        className="editable-input"
      />
      <span className="edit-icon">
        <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
      </span>
    </label>
    // </div>
  );

  const renderEditableDoubleField = (label: string, name: string, value: number | string = '', onChange: (val: number) => void) => (
    // <div className="editable-container">
    <label className="horizontal-label">
      <span className="label-text">{label}</span>
      <input
        type="number"
        step="0.01"
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value) || 0)}
        className="editable-input"
      />
      <span className="edit-icon">
        <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
      </span>
    </label>
    // </div>
  );

  const renderEditableDateField = (
    label: string,
    name: string,
    value: string,
    onChange: (val: string) => void
  ) => {
    let formattedDate = '';

    // can we check if it is not null or undefined, if it is return?
    if (!value) {
      return null;
    }


    console.log('VALUE:', value);
    if (value.includes('.')) {
      const dateParts = value.split('.');

      if (dateParts.length === 3) {
        // Handling "dd.mm.yyyy" format
        formattedDate = `${dateParts[2]}-${dateParts[1].padStart(2, '0')}-${dateParts[0].padStart(2, '0')}`;
      } else if (dateParts.length === 2) {
        // Handling "mm.yyyy" format, setting default day to "01"
        formattedDate = `${dateParts[1]}-${dateParts[0].padStart(2, '0')}-01`;
      }
    }

    return (
      <label className="horizontal-label">
        <span className="label-text">{label}</span>
        <input
          type="date"
          id={name}
          name={name}
          value={formattedDate}
          onChange={(e) => {
            const selectedDate = e.target.value;
            const newDateParts = selectedDate.split('-');

            let formattedSelectedDate = '';
            if (newDateParts.length === 3) {
              if (value.includes('.')) {
                const originalParts = value.split('.');
                if (originalParts.length === 2) {
                  // Convert "yyyy-mm-dd" back to "mm.yyyy"
                  formattedSelectedDate = `${newDateParts[1]}.${newDateParts[0]}`;
                } else if (originalParts.length === 3) {
                  // Convert "yyyy-mm-dd" back to "dd.mm.yyyy"
                  formattedSelectedDate = `${newDateParts[2]}.${newDateParts[1]}.${newDateParts[0]}`;
                }
              }
            }
            onChange(formattedSelectedDate);
          }}
          className="editable-input"
        />
        <span className="edit-icon">
          <i className="fa fa-edit"></i> {/* FontAwesome edit icon */}
        </span>
      </label>
    );
  };





  if (!user) {
    return <div>Bitte melden Sie sich an, um Details zu sehen.</div>;
  }

  if (!property) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="detail-container">
      <ToastContainer />
      <h2>{editMode ? 'Fahrzeug editieren' : 'Fahrzeug'}</h2>
      <h1>{property.title}</h1>
      {property.mainImageURL && <img src={property.mainImageURL} alt={property.title} className="detail-property-image" />}
      <div className="property-details">
        {editMode ? (
          <form onSubmit={handleSave} className="add-car-form">
            <div className="form-group-horizontal">
              {renderCheckboxField("Gebrauchtwagen", "usedCar", property.usedCar)}
            </div>
            <div className="form-group-horizontal">
              {renderCheckboxField("Radar", "radar", property.radar)}
            </div>
            <div className="form-group">
              <label htmlFor="title">Titel</label>
              <input type="text" id="title" name="title" value={property.title} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="type">Marke</label>
              <select id="type" name="type" value={property.type} onChange={handleInputChange} required>
                {Object.keys(carBrandModelsMap).map(brand => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="model">Modell</label>
              <select id="model" name="model" value={property.model} onChange={handleInputChange} required>
                {models.map(model => (
                  <option key={model} value={model}>{model}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="price">Preis (€)</label>
              <input type="number" id="price" name="price" value={property.price} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="city">Ort</label>
              <AddressSearch onSelectAddress={handleSelectAddress} onInputAddress={handleAddressInput} isValidAddress={false} initialValue={property.city} />
              {!isValidAddress && <span className="address-feedback">{addressFeedback}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="state">Bundesland</label>
              <input type="text" id="state" name="state" value={property.state} onChange={handleInputChange} readOnly />
            </div>
            <div className="form-group">
              <label htmlFor="year">Erstzulassungsdatum</label>
              <div className="date-inputs">
                <select id="year" name="year" value={property.year} onChange={handleInputChange} required>
                  {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <select id="month" name="month" value={property.month} onChange={handleInputChange} required>
                  {months.map(month => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="performance">Leistung (PS)</label>
              <input type="number" id="performance" name="performance" value={property.performance} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="mileage">Kilometerstand</label>
              <input type="number" id="mileage" name="mileage" value={property.mileage} onChange={handleInputChange} required />
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'sicherheit' ? 'open' : ''}`} onClick={() => toggleSection('sicherheit')}>
                Sicherheit
                <div className="arrow"></div>
              </div>
              {expandedSection === 'sicherheit' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Airbag", "airbag", property.airbag)}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Airbag-Details"
                      fieldName="airbagExtended"
                      value={property.airbagExtended}
                      options={options.airbagExtended}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">{renderCheckboxField("ABS", "abs", property.abs)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("ESP", "esp", property.esp)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Isofix", "isofix", property.isofix)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Isofix Beifahrer", "isofixPassengerSeat", property.isofixPassengerSeat)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Notbremsassistent", "emergencyBrake", property.emergencyBrake)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Berganfahrassistent", "hillStartAssist", property.hillStartAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Totwinkelassistent", "blindSpotAssist", property.blindSpotAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Spurhalteassistent", "laneKeepAssist", property.laneKeepAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Rückfahrkamera", "rearViewCamera", property.rearViewCamera)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Einparkassistent", "parkingAssist", property.parkingAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Müdigkeitswarnsystem", "fatigueDetection", property.fatigueDetection)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Nachtsichtassistent", "nightVisionAssist", property.nightVisionAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kreuzungsassistent", "intersectionAssist", property.intersectionAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Verkehrszeichenerkennung", "trafficSignRecognition", property.trafficSignRecognition)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kurvenlicht adaptiv", "adaptiveHeadlights", property.adaptiveHeadlights)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektronisches Stabilitätsprogramm (ESP)", "electronicStabilityProgram", property.electronicStabilityProgram)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Wegfahrsperre", "immobilizer", property.immobilizer)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektronisches Bremssystem (EBS)", "electronicBrakeSystem", property.electronicBrakeSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Bremsassistent (BSS)", "brakingSystemSupport", property.brakingSystemSupport)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Traktionskontrolle", "tractionControl", property.tractionControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kurvenlicht", "corneringLight", property.corneringLight)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Nebelscheinwerfer", "fogLights", property.fogLights)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Abstandstempomat", "adaptiveCruiseControl", property.adaptiveCruiseControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Abstandswarner", "distanceWarning", property.distanceWarning)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Spurwechselassistent", "laneChangeAssistant", property.laneChangeAssistant)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Alarmanlage", "alarmSystem", property.alarmSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Innenspiegel automatisch abblendend", "autoDimmingInteriorMirror", property.autoDimmingInteriorMirror)}</div>
                  {/* <div className="form-group-horizontal">{renderCheckboxField("MwSt.", "vat", property.vat)}</div> */}
                  <div className="form-group-horizontal">{renderEditableTextField("VIN", "vin", property.vin, (val) => handleVariableChange("vin", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Türen", "numberOfDoors", property.numberOfDoors, (val) => handleVariableChange("numberOfDoors", val))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="MwSt."
                      fieldName="vat"
                      value={property.vat}
                      options={options.vatRate}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Umweltplakette"
                      fieldName="emissionSticker"
                      value={property.emissionSticker}
                      options={options.emissionSticker}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Antrieb"
                      fieldName="driveType"
                      value={property.driveType}
                      options={options.driveType}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Gesamtgewicht (kg)", "grossWeight", property.grossWeight, (val) => handleVariableChange("grossWeight", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Baujahr", "yearOfManufacture", property.yearOfManufacture, (val) => handleVariableChange("yearOfManufacture", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("CO2-Emissionen (g/km)", "co2Emission", property.co2Emission, (val) => handleVariableChange("co2Emission", val))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Schadstoffklasse"
                      fieldName="emissionStandard"
                      value={property.emissionStandard}
                      options={options.emissionStandard}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">{renderEditableTextField("Qualitätssiegel", "qualitySeal", property.qualitySeal, (val) => handleVariableChange("qualitySeal", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Achsen", "numberOfAxles", property.numberOfAxles, (val) => handleVariableChange("numberOfAxles", val))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Hydraulikanlage"
                      fieldName="hydraulicSystem"
                      value={property.hydraulicSystem}
                      options={options.hydraulicSystem}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Radformel"
                      fieldName="wheelFormula"
                      value={property.wheelFormula}
                      options={options.wheelFormula}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Vorbesitzer", "previousOwners", property.previousOwners, (val) => handleVariableChange("previousOwners", val))}</div>
                  {/* <div className="form-group-horizontal">{renderEditableTextField("Zielgruppe", "targetGroup", property.targetGroup, (val) => handleVariableChange("targetGroup", val))}</div> */}
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Zielgruppe"
                      fieldName="targetGroup"
                      value={property.targetGroup}
                      options={options.targetGroup}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Schadstoffklassedetail"
                      fieldName="emissionClassDetail"
                      value={property.emissionClassDetail}
                      options={options.emissionClassDetail}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">{renderEditableNumericField("CO2 Emission WLTP (g/km)", "co2EmissionWltp", property.co2EmissionWltp, (val) => handleVariableChange("co2EmissionWltp", val))}</div>
                </div>
              )}
            </div>


            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'komfort' ? 'open' : ''}`} onClick={() => toggleSection('komfort')}>
                Komfort
                <div className="arrow"></div>
              </div>
              {expandedSection === 'komfort' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Klimaanlage", "airConditioning", property.airConditioning)}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Klimaanlage-Detail"
                      fieldName="climateControl"
                      value={property.climateControl}
                      options={options.climateControl}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">{renderCheckboxField("Tempomat", "cruiseControl", property.cruiseControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sitzheizung/Kühlung", "seatHeatingCooling", property.seatHeatingCooling)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Fensterheber", "electricWindows", property.electricWindows)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektrische Sitzeinstellung", "electricSeatAdjustment", property.electricSeatAdjustment)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Schlüssellose Zentralverriegelung", "keylessGo", property.keylessGo)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Zentralverriegelung", "centralLocking", property.centralLocking)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Panoramadach", "panoramicGlassRoof", property.panoramicGlassRoof)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Lederpolsterung", "leatherUpholstery", property.leatherUpholstery)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Lenkradheizung", "steeringWheelHeating", property.steeringWheelHeating)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Massagesitze", "massageSeats", property.massageSeats)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Ambientebeleuchtung", "ambientLighting", property.ambientLighting)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("2-Zonen-Klimaautomatik", "twoZoneClimateControl", property.twoZoneClimateControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("3-Zonen-Klimaautomatik", "threeZoneClimateControl", property.threeZoneClimateControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("4-Zonen-Klimaautomatik", "fourZoneClimateControl", property.fourZoneClimateControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("5-Zonen-Klimaautomatik", "fiveZoneClimateControl", property.fiveZoneClimateControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektrische Heckrollo", "electricSunshadeRear", property.electricSunshadeRear)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Head-Up Display", "headUpDisplay", property.headUpDisplay)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Servolenkung", "powerSteering", property.powerSteering)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Schiebedach", "sunroof", property.sunroof)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sitzheizung hinten", "rearSeatHeating", property.rearSeatHeating)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektrische Sitzeinstellung hinten", "rearElectricSeatAdjustment", property.rearElectricSeatAdjustment)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Raucherpaket", "smokerPackage", property.smokerPackage)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Umklappbarer Beifahrersitz", "foldablePassengerSeat", property.foldablePassengerSeat)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Mittelarmlehne", "centerArmrest", property.centerArmrest)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektrische Heckklappe", "electricTailgate", property.electricTailgate)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Lenkrad höhenverstellbar", "adjustableSteeringWheel", property.adjustableSteeringWheel)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Fahrersitz Höhenverstellbar", "heightAdjustableDriverSeat", property.heightAdjustableDriverSeat)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Rückbank umklappbar", "foldableRearSeat", property.foldableRearSeat)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Softclose", "softClose", property.softClose)}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Farbe", "color", property.color, (val) => handleVariableChange("color", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Sitze", "seatingCapacity", property.seatingCapacity, (val) => handleVariableChange("seatingCapacity", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Schlafplätze", "sleepingPlaces", property.sleepingPlaces, (val) => handleVariableChange("sleepingPlaces", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Fahrzeuglänge (mm)", "vehicleLength", property.vehicleLength, (val) => handleVariableChange("vehicleLength", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Fahrzeugbreite (mm)", "vehicleWidth", property.vehicleWidth, (val) => handleVariableChange("vehicleWidth", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Fahrzeughöhe (mm)", "vehicleHeight", property.vehicleHeight, (val) => handleVariableChange("vehicleHeight", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laderaum Europaletten (Stück)", "loadingAreaPallets", property.loadingAreaPallets, (val) => handleVariableChange("loadingAreaPallets", val))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laderaumvolumen (m³)", "loadingAreaVolume", property.loadingAreaVolume, (val) => handleVariableChange("loadingAreaVolume", val))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Interieurfarbe"
                      fieldName="interiorColor"
                      value={property.interiorColor}
                      options={options.interiorColor}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Interieurtyp"
                      fieldName="interiorType"
                      value={property.interiorType}
                      options={options.interiorType}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Kabinenart"
                      fieldName="cabinType"
                      value={property.cabinType}
                      options={options.cabinType}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                </div>
              )}
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'infotainment' ? 'open' : ''}`} onClick={() => toggleSection('infotainment')}>
                Infotainment und Navigation
                <div className="arrow"></div>
              </div>
              {expandedSection === 'infotainment' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Touchscreen System", "touchscreenSystem", property.touchscreenSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Navigationssystem", "realtimeNavigationSystem", property.realtimeNavigationSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Bluetooth", "bluetoothConnection", property.bluetoothConnection)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("CarPlay/Android Auto", "carPlayAndroidAuto", property.carPlayAndroidAuto)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Soundsystem", "soundSystem", property.soundSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sprachsteuerung", "voiceControl", property.voiceControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("USB", "usbAux", property.usbAux)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("DAB Radio", "dabRadio", property.dabRadio)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Wireless Charging", "wirelessCharging", property.wirelessCharging)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("WLAN Hotspot", "wifiHotspot", property.wifiHotspot)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("AR Navigation", "arNavigation", property.arNavigation)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Onboard Apps/Services", "onboardAppsServices", property.onboardAppsServices)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Gestensteuerung Multimedia", "gestureControlMultimedia", property.gestureControlMultimedia)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Tuner oder Radio", "digitalRadio", property.digitalRadio)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Bordcomputer", "onBoardComputer", property.onBoardComputer)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("CD-Spieler", "cdPlayer", property.cdPlayer)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Freisprecheinrichtung", "handsFreeSystem", property.handsFreeSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("MP3-Schnittstelle", "mp3Interface", property.mp3Interface)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Audio-Aux-Anschluss", "audioAuxInput", property.audioAuxInput)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("iPad/iPod-Anschluss", "iPadPodConnection", property.iPadPodConnection)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Volldigitales Kombiinstrument", "fullyDigitalInstrumentCluster", property.fullyDigitalInstrumentCluster)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Musikstreaming integriert", "integratedMusicStreaming", property.integratedMusicStreaming)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Induktionsladen Smartphones", "wirelessChargingForSmartphones", property.wirelessChargingForSmartphones)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("WLAN", "wifi", property.wifi)}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Schwacke-Code", "schwackeCode", property.schwackeCode, (value) => handleVariableChange("schwackeCode", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Bild ID", "imageId", property.imageId, (value) => handleVariableChange("imageId", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Video-URL", "videoUrl", property.videoUrl, (value) => handleVariableChange("videoUrl", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Highlight 1", "highlight1", property.highlight1, (value) => handleVariableChange("highlight1", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Highlight 2", "highlight2", property.highlight2, (value) => handleVariableChange("highlight2", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Highlight 3", "highlight3", property.highlight3, (value) => handleVariableChange("highlight3", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Twinner Widget", "twinnerWidget", property.twinnerWidget, (value) => handleVariableChange("twinnerWidget", value))}</div>
                </div>
              )}
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'exterieurDesign' ? 'open' : ''}`} onClick={() => toggleSection('exterieurDesign')}>
                Exterieur und Design
                <div className="arrow"></div>
              </div>
              {expandedSection === 'exterieurDesign' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("LED/Xenon Scheinwerfer", "ledXenonHeadlights", property.ledXenonHeadlights)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Tagfahrlicht", "daytimeRunningLights", property.daytimeRunningLights)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Leichtmetallfelgen", "alloyWheels", property.alloyWheels)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elektrische Seitenspiegel", "electricFoldingMirrors", property.electricFoldingMirrors)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Dachreling", "roofRails", property.roofRails)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Metallic Lackierung", "metallicPaint", property.metallicPaint)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Panorama-Schiebedach", "panoramicSlidingRoof", property.panoramicSlidingRoof)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Dynamische Blinker", "dynamicIndicators", property.dynamicIndicators)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Privacy Verglasung", "privacyGlazing", property.privacyGlazing)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heckdiffusor", "rearDiffuser", property.rearDiffuser)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Bunte Bremssättel", "coloredBrakeCalipers", property.coloredBrakeCalipers)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Rechte Schiebetür", "rightSlidingDoor", property.rightSlidingDoor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Linke Schiebetür", "leftSlidingDoor", property.leftSlidingDoor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Schiebetür beidseitig", "doubleSlidingDoors", property.doubleSlidingDoors)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Dachträger", "roofRack", property.roofRack)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Optikschwarz", "blackOptics", property.blackOptics)}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Farbe", "color", property.color, (value) => handleVariableChange("color", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Ausstattungslinie", "equipmentLine", property.equipmentLine, (value) => handleVariableChange("equipmentLine", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Bauart", "constructionType", property.constructionType, (value) => handleVariableChange("constructionType", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Modellbasis", "modelBase", property.modelBase, (value) => handleVariableChange("modelBase", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Modelltext (erweitert)", "extendedModelText", property.extendedModelText, (value) => handleVariableChange("extendedModelText", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Modellschlüssel", "modelKey", property.modelKey, (value) => handleVariableChange("modelKey", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Kampagnen-Identifikator", "campaignIdentifier", property.campaignIdentifier, (value) => handleVariableChange("campaignIdentifier", value))}</div>
                </div>
              )}
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'technologiePerformance' ? 'open' : ''}`} onClick={() => toggleSection('technologiePerformance')}>
                Technologie und Performance
                <div className="arrow"></div>
              </div>
              {expandedSection === 'technologiePerformance' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Start/Stopp Automatik", "startStopSystem", property.startStopSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Hybrid/Elektro Antrieb", "hybridElectricDrive", property.hybridElectricDrive)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Adaptive Fahrwerksregelung", "adaptiveSuspension", property.adaptiveSuspension)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Fahrmodi", "drivingModes", property.drivingModes)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Allradantrieb", "allWheelDrive", property.allWheelDrive)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Aktive Aerodynamik", "activeAerodynamics", property.activeAerodynamics)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Launch Control", "launchControl", property.launchControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Zylinderabschaltung", "cylinderDeactivation", property.cylinderDeactivation)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Dynamische Motorlager", "dynamicEngineMounts", property.dynamicEngineMounts)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Aktive Lenkung", "activeSteering", property.activeSteering)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Luftfederung", "airSuspension", property.airSuspension)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Allradlenkung", "allWheelSteering", property.allWheelSteering)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Keramikbremse", "ceramicBrakes", property.ceramicBrakes)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sportfahrwerk", "sportsSuspension", property.sportsSuspension)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sportabgasanlage", "sportsExhaustSystem", property.sportsExhaustSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Elek. Parkbremse", "electricParkingBrake", property.electricParkingBrake)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Wärmepumpe", "heatPump", property.heatPump)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Quickdrop-System", "quickdropSystem", property.quickdropSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Range Extender", "rangeExtender", property.rangeExtender)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Plugin Hybrid", "pluginHybrid", property.pluginHybrid)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Blendfreies Fernlicht (Matrix)", "matrixBeamHeadlights", property.matrixBeamHeadlights)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Fernlichtassistent", "highBeamAssistant", property.highBeamAssistant)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Partikelfilter", "particulateFilter", property.particulateFilter)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("SCR-System", "scrSystem", property.scrSystem)}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Hubraum (ccm)", "displacement", property.displacement, (value) => handleVariableChange("displacement", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Traglast (kg)", "loadCapacity", property.loadCapacity, (value) => handleVariableChange("loadCapacity", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Nutzlast (kg)", "payloadCapacity", property.payloadCapacity, (value) => handleVariableChange("payloadCapacity", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Hubhöhe (mm)", "liftHeight", property.liftHeight, (value) => handleVariableChange("liftHeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Bauhöhe (mm)", "constructionHeight", property.constructionHeight, (value) => handleVariableChange("constructionHeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Betriebsstunden", "operatingHours", property.operatingHours, (value) => handleVariableChange("operatingHours", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch innerorts (l/100km)", "cityFuelConsumption", property.cityFuelConsumption, (value) => handleVariableChange("cityFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch außerorts (l/100km)", "highwayFuelConsumption", property.highwayFuelConsumption, (value) => handleVariableChange("highwayFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch kombiniert (l/100km)", "combinedFuelConsumption", property.combinedFuelConsumption, (value) => handleVariableChange("combinedFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laderaumlänge (mm)", "loadingAreaLength", property.loadingAreaLength, (value) => handleVariableChange("loadingAreaLength", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laderaumbreite (mm)", "loadingAreaWidth", property.loadingAreaWidth, (value) => handleVariableChange("loadingAreaWidth", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laderaumhöhe (mm)", "loadingAreaHeight", property.loadingAreaHeight, (value) => handleVariableChange("loadingAreaHeight", value))}</div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Energieeffizienzklasse"
                      fieldName="energyEfficiencyClass"
                      value={property.energyEfficiencyClass}
                      options={options.energyEfficiencyClass}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">{renderEditableTextField("Kraftstoffsorte", "petrolType", property.petrolType, (value) => handleVariableChange("petrolType", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Kombinierter Stromverbrauch (kWh/100km)", "combinedElectricConsumption", property.combinedElectricConsumption, (value) => handleVariableChange("combinedElectricConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laufleistung (km)", "totalMileage", property.totalMileage, (value) => handleVariableChange("totalMileage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Landesversion", "countryVersion", property.countryVersion, (value) => handleVariableChange("countryVersion", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Produktionsdatum", "productionDate", property.productionDate, (date) => handleVariableChange("productionDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Leergewicht (kg)", "emptyWeight", property.emptyWeight, (value) => handleVariableChange("emptyWeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("WLTP Kraftstoffverbrauch kombiniert (l/100km)", "combinedWltpFuelConsumption", property.combinedWltpFuelConsumption, (value) => handleVariableChange("combinedWltpFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("WLTP Kraftstoffverbrauch Innenstadt (l/100km)", "wltpCityFuelConsumption", property.wltpCityFuelConsumption, (value) => handleVariableChange("wltpCityFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("WLTP Kraftstoffverbrauch Stadtrand (l/100km)", "wltpSuburbanFuelConsumption", property.wltpSuburbanFuelConsumption, (value) => handleVariableChange("wltpSuburbanFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("WLTP Kraftstoffverbrauch Landstraße (l/100km)", "wltpHighwayFuelConsumption", property.wltpHighwayFuelConsumption, (value) => handleVariableChange("wltpHighwayFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("WLTP Kraftstoffverbrauch Autobahn (l/100km)", "wltpAutobahnFuelConsumption", property.wltpAutobahnFuelConsumption, (value) => handleVariableChange("wltpAutobahnFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Schwacke WENR", "schwackeWenr", property.schwackeWenr, (value) => handleVariableChange("schwackeWenr", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("DAT SD3 Aktennummer", "datSd3FileNumber", property.datSd3FileNumber, (value) => handleVariableChange("datSd3FileNumber", value))}</div>
                </div>
              )}
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'reifenRaeder' ? 'open' : ''}`} onClick={() => toggleSection('reifenRaeder')}>
                Reifen und Räder
                <div className="arrow"></div>
              </div>
              {expandedSection === 'reifenRaeder' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Allwetterreifen", "allSeasonTires", property.allSeasonTires)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sommerreifen", "summerTires", property.summerTires)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Winterreifen", "winterTires", property.winterTires)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Notrad", "spareWheel", property.spareWheel)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Pannenkitt", "tireSealant", property.tireSealant)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Reserverad", "spareTire", property.spareTire)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Stahlfelge", "steelRims", property.steelRims)}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Handelsname Lieferant", "supplierTradeName", property.supplierTradeName, (value) => handleVariableChange("supplierTradeName", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Reifentypkennung", "tireTypeDesignation", property.tireTypeDesignation, (value) => handleVariableChange("tireTypeDesignation", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Reifenbezeichnung", "tireLabelDesignation", property.tireLabelDesignation, (value) => handleVariableChange("tireLabelDesignation", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Tragfähigkeitsindex", "loadIndex", property.loadIndex, (value) => handleVariableChange("loadIndex", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Geschwindigkeitsindex", "speedIndex", property.speedIndex, (value) => handleVariableChange("speedIndex", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Kraftstoffeffizienzklasse", "fuelEfficiencyClass", property.fuelEfficiencyClass, (value) => handleVariableChange("fuelEfficiencyClass", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Nasshaftungsklasse", "wetGripClass", property.wetGripClass, (value) => handleVariableChange("wetGripClass", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Externe Rollgeräuschklasse", "externalRollingNoiseClass", property.externalRollingNoiseClass, (value) => handleVariableChange("externalRollingNoiseClass", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Wert externe Rollgeräusch (dB)", "externalRollingNoiseValue", property.externalRollingNoiseValue, (value) => handleVariableChange("externalRollingNoiseValue", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Herstellungsbeginn Reifentyp", "tireProductionStartDate", property.tireProductionStartDate, (date) => handleVariableChange("tireProductionStartDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Herstellungsende Reifentyp", "tireProductionEndDate", property.tireProductionEndDate, (date) => handleVariableChange("tireProductionEndDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Handelsname", "tradeName", property.tradeName, (value) => handleVariableChange("tradeName", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Reifenzusatzinfo", "tireAdditionalInfo", property.tireAdditionalInfo, (value) => handleVariableChange("tireAdditionalInfo", value))}</div>
                </div>
              )}
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'elektrischeKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('elektrischeKomponenten')}>
                Elektrische Komponenten
                <div className="arrow"></div>
              </div>
              {expandedSection === 'elektrischeKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("E-Fahrzeug-Batterie fehlt", "batteryMissing", property.batteryMissing)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("E-Fahrzeug-Batterie gekauft", "batteryPurchased", property.batteryPurchased)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("E-Fahrzeug-Batterie gemietet", "batteryRented", property.batteryRented)}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Ladeleistung AC (kW)", "acChargingPower", property.acChargingPower, (value) => handleVariableChange("acChargingPower", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Ladeleistung DC (kW)", "dcChargingPower", property.dcChargingPower, (value) => handleVariableChange("dcChargingPower", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("EU-Reifenregisternummer (vorne)", "euTireRegistrationNumberFront", property.euTireRegistrationNumberFront, (value) => handleVariableChange("euTireRegistrationNumberFront", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Schlüsselbrettnummer", "keyBoardNumber", property.keyBoardNumber, (value) => handleVariableChange("keyBoardNumber", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Garantie Monate", "warrantyMonths", property.warrantyMonths, (value) => handleVariableChange("warrantyMonths", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Modellreihe", "modelSeries", property.modelSeries, (value) => handleVariableChange("modelSeries", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Finanzierungsprodukt", "financingProduct", property.financingProduct, (value) => handleVariableChange("financingProduct", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Leasingprodukt", "leasingProduct", property.leasingProduct, (value) => handleVariableChange("leasingProduct", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Motorleistung (max) (kW)", "maxMotorPower", property.maxMotorPower, (value) => handleVariableChange("maxMotorPower", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Batteriespannung (Volts)", "batteryVoltage", property.batteryVoltage, (value) => handleVariableChange("batteryVoltage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Batteriekapazität (kWh)", "batteryCapacity", property.batteryCapacity, (value) => handleVariableChange("batteryCapacity", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Standardladung (Volts)", "standardChargingVoltage", property.standardChargingVoltage, (value) => handleVariableChange("standardChargingVoltage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Ladedauer bei Standardladung (AC) (Wallbox/Ladestation)", "standardChargingDuration", property.standardChargingDuration, (value) => handleVariableChange("standardChargingDuration", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Schnellladung (Volts)", "fastChargingVoltage", property.fastChargingVoltage, (value) => handleVariableChange("fastChargingVoltage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Ladedauer bei Schnellladung (DC) (Wallbox/Ladestation)", "fastChargingDuration", property.fastChargingDuration, (value) => handleVariableChange("fastChargingDuration", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Elektromotor-Reichweite nach WLTP (km)", "wltpElectricRange", property.wltpElectricRange, (value) => handleVariableChange("wltpElectricRange", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Elektromotor-Reichweite innerorts nach WLTP (km)", "wltpInnerCityElectricRange", property.wltpInnerCityElectricRange, (value) => handleVariableChange("wltpInnerCityElectricRange", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Gesamtreichweite nach WLTP (km)", "totalWltpRange", property.totalWltpRange, (value) => handleVariableChange("totalWltpRange", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Gesamtreichweite innerorts nach WLTP (km)", "wltpInnerCityTotalRange", property.wltpInnerCityTotalRange, (value) => handleVariableChange("wltpInnerCityTotalRange", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("MobileAD-ID", "mobileAdId", property.mobileAdId, (value) => handleVariableChange("mobileAdId", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Gewichtete kombinierte CO2-Emissionen WLTP (g/km)", "weightedWltpCo2Emissions", property.weightedWltpCo2Emissions, (value) => handleVariableChange("weightedWltpCo2Emissions", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Gewichteter kombinierter Stromverbrauch WLTP (kWh/100km)", "weightedWltpElectricConsumption", property.weightedWltpElectricConsumption, (value) => handleVariableChange("weightedWltpElectricConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Gewichteter kombinierter Kraftstoffverbrauch WLTP (l/100km)", "weightedWltpFuelConsumption", property.weightedWltpFuelConsumption, (value) => handleVariableChange("weightedWltpFuelConsumption", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Mängelhinweis/Unfallinfo", "damageOrAccidentInfo", property.damageOrAccidentInfo, (value) => handleVariableChange("damageOrAccidentInfo", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Hinweis Leasingangebot", "leasingOfferInfo", property.leasingOfferInfo, (value) => handleVariableChange("leasingOfferInfo", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Hinweis Finanzierungsangebot", "financingOfferInfo", property.financingOfferInfo, (value) => handleVariableChange("financingOfferInfo", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("EU-Reifenregisternummer (hinten)", "euTireRegistrationNumberRear", property.euTireRegistrationNumberRear, (value) => handleVariableChange("euTireRegistrationNumberRear", value))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Aktivkopfstütze"
                      fieldName="activeHeadrest"
                      value={property.activeHeadrest}
                      options={options.activeHeadrest}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Autonomes Fahren"
                      fieldName="autonomousDriving"
                      value={property.autonomousDriving}
                      options={options.autonomousDriving}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Batteriebauart"
                      fieldName="batteryType"
                      value={property.batteryType}
                      options={options.batteryType}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Ladestromstecker"
                      fieldName="chargingPlug"
                      value={property.chargingPlug}
                      options={options.chargingPlug}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                </div>
              )}
            </div>
            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'abodataKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('abodataKomponenten')}>
                Abo-Daten
                <div className="arrow"></div>
              </div>
              {expandedSection === 'abodataKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Standort", "subscriptionLocation", property.subscriptionLocation, (value) => handleVariableChange("subscriptionLocation", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Abo Mindestalter in Jahre", "subscriptionMinAge", property.subscriptionMinAge, (value) => handleVariableChange("subscriptionMinAge", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abo Selbstbeteiligung (Euro)", "subscriptionDeductible", property.subscriptionDeductible, (value) => handleVariableChange("subscriptionDeductible", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abo Kaution (Euro)", "subscriptionDeposit", property.subscriptionDeposit, (value) => handleVariableChange("subscriptionDeposit", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Vertragsbedingungen", "subscriptionTerms", property.subscriptionTerms, (value) => handleVariableChange("subscriptionTerms", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Abo Mehrkilometer (km)", "subscriptionExtraKm", property.subscriptionExtraKm, (value) => handleVariableChange("subscriptionExtraKm", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Lieferoptionen", "subscriptionDeliveryOptions", property.subscriptionDeliveryOptions, (value) => handleVariableChange("subscriptionDeliveryOptions", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abo Lieferkosten (Euro)", "subscriptionDeliveryCost", property.subscriptionDeliveryCost, (value) => handleVariableChange("subscriptionDeliveryCost", value))}</div>
                  <div className="form-group-horizontal">{options.displaySubscriptionCost(property.subscriptionDeliveryCostMatrix)}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Bemerkung", "subscriptionNote", property.subscriptionNote, (value) => handleVariableChange("subscriptionNote", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Highlight", "subscriptionHighlight", property.subscriptionHighlight, (value) => handleVariableChange("subscriptionHighlight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Toptags", "subscriptionTopTags", property.subscriptionTopTags, (value) => handleVariableChange("subscriptionTopTags", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abo Rate (Euro)", "subscriptionRate", property.subscriptionRate, (value) => handleVariableChange("subscriptionRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Rate Matrix", "subscriptionRateMatrix", property.subscriptionRateMatrix, (value) => handleVariableChange("subscriptionRateMatrix", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abo Startgebühr (Euro)", "subscriptionStartFee", property.subscriptionStartFee, (value) => handleVariableChange("subscriptionStartFee", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Abo Teaser Detailseite URL", "teaserImageUrl", property.teaserImageUrl, (value) => handleVariableChange("teaserImageUrl", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Interner Identifier", "internalIdentifier", property.internalIdentifier, (value) => handleVariableChange("internalIdentifier", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Anzahl Bilder", "numberOfImages", property.numberOfImages, (value) => handleVariableChange("numberOfImages", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Abo Lieferdatum", "subscriptionDeliveryDate", property.subscriptionDeliveryDate, (value) => handleVariableChange("subscriptionDeliveryDate", value))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Abo Verfügbarkeit"
                      fieldName="subscriptionAvailability"
                      value={property.subscriptionAvailability}
                      options={options.subscriptionAvailability}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  {/* <div className="form-group-horizontal">
                    <DropdownField
                      label="Abo Lieferdatum"
                      fieldName="subscriptionDeliveryDate"
                      value={property.subscriptionDeliveryDate}
                      options={options.subscriptionDeliveryDate}
                      onChange={handleVariableChangeDropdown}
                    /></div> */}
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Abo Lieferfrist in Tagen"
                      fieldName="subscriptionDeliveryTimeframeInDays"
                      value={property.subscriptionDeliveryTimeframeInDays}
                      options={options.subscriptionDeliveryTimeframeInDays}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Abo Zahlungsbedingungen"
                      fieldName="subscriptionPaymentTerms"
                      value={property.subscriptionPaymentTerms}
                      options={options.subscriptionPaymentTerms}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                </div>
              )}
            </div>
            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'serviceandmaintenanceKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('serviceandmaintenanceKomponenten')}>
                Service und Wartung
                <div className="arrow"></div>
              </div>
              {expandedSection === 'serviceandmaintenanceKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderEditableDateField("Letzter großer Kundendienst", "lastMajorServiceDate", property.lastMajorServiceDate, (value) => handleVariableChange("lastMajorServiceDate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Kilometerstand letzter großer Kundendienst", "lastMajorServiceMileage", property.lastMajorServiceMileage, (value) => handleVariableChange("lastMajorServiceMileage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Letzter kleiner Kundendienst", "lastMinorServiceDate", property.lastMinorServiceDate, (value) => handleVariableChange("lastMinorServiceDate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Kilometerstand letzter kleiner Kundendienst", "lastMinorServiceMileage", property.lastMinorServiceMileage, (value) => handleVariableChange("lastMinorServiceMileage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Letzter Ölwechsel", "lastOilChangeDate", property.lastOilChangeDate, (value) => handleVariableChange("lastOilChangeDate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Kilometerstand letzter Ölwechsel", "lastOilChangeMileage", property.lastOilChangeMileage, (value) => handleVariableChange("lastOilChangeMileage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Letzter Bremsenwechsel", "lastBrakeChangeDate", property.lastBrakeChangeDate, (value) => handleVariableChange("lastBrakeChangeDate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Kilometerstand letzter Bremsenwechsel", "lastBrakeChangeMileage", property.lastBrakeChangeMileage, (value) => handleVariableChange("lastBrakeChangeMileage", value))}</div>
                </div>
              )}
            </div>
            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'vehicledateeKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('vehicledateKomponenten')}>
                Fahrzeugdaten
                <div className="arrow"></div>
              </div>
              {expandedSection === 'vehicledateKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Top Inserat", "topAd", property.topAd)}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Zylinder", "cylinderCount", property.cylinderCount, (value) => handleVariableChange("cylinderCount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Modelljahr", "modelYear", property.modelYear, (value) => handleVariableChange("modelYear", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Vorbesitzername", "previousOwnerName", property.previousOwnerName, (value) => handleVariableChange("previousOwnerName", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Twinner 360Grad URL", "twinner360Url", property.twinner360Url, (value) => handleVariableChange("twinner360Url", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Versionsschlüssel", "versionKey", property.versionKey, (value) => handleVariableChange("versionKey", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Grundfarbe aussen", "exteriorBaseColor", property.exteriorBaseColor, (value) => handleVariableChange("exteriorBaseColor", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Anzahl Gänge", "gearCount", property.gearCount, (value) => handleVariableChange("gearCount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Kilometerstand für Börsen (km)", "marketMileage", property.marketMileage, (value) => handleVariableChange("marketMileage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("kombinierter Stromverbrauch WLTP (kWh/100km)", "combinedElectricConsumptionWltp", property.combinedElectricConsumptionWltp, (value) => handleVariableChange("combinedElectricConsumptionWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("DAT Europa Code", "datEuropeCode", property.datEuropeCode, (value) => handleVariableChange("datEuropeCode", value))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Vornutzung"
                      fieldName="previousUse"
                      value={property.previousUse}
                      options={options.previousUse}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Benzinsorte"
                      fieldName="fuelGrade"
                      value={property.fuelGrade}
                      options={options.fuelGrade}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                </div>
              )}
            </div>
            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'furthervehicledateeKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('furthervehicledateKomponenten')}>
                Weitere Fahrzeugdaten
                <div className="arrow"></div>
              </div>
              {expandedSection === 'furthervehicledateKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderEditableTextField("Einkäufer", "buyer", property.buyer, (value) => handleVariableChange("buyer", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Interne Informationen", "internalInfo", property.internalInfo, (value) => handleVariableChange("internalInfo", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("CO2-Emissionen (WLTP) bei entladener Batterie", "co2EmissionsWltpDischargedBattery", property.co2EmissionsWltpDischargedBattery, (value) => handleVariableChange("co2EmissionsWltpDischargedBattery", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Kombinierter Kraftstoffverbrauch (WLTP) bei entladener Batterie (l/100km, kg/100km)", "combinedFuelConsumptionWltpDischargedBattery", property.combinedFuelConsumptionWltpDischargedBattery, (value) => handleVariableChange("combinedFuelConsumptionWltpDischargedBattery", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("State of Health (SOH) in % (Batteriezustand)", "batteryStateOfHealth", property.batteryStateOfHealth, (value) => handleVariableChange("batteryStateOfHealth", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Stromverbrauch Innenstadt WLTP (kWh/100km)", "cityElectricConsumptionWltp", property.cityElectricConsumptionWltp, (value) => handleVariableChange("cityElectricConsumptionWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Stromverbrauch Stadtrand WLTP (kWh/100km)", "suburbanElectricConsumptionWltp", property.suburbanElectricConsumptionWltp, (value) => handleVariableChange("suburbanElectricConsumptionWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Stromverbrauch Landstraße WLTP (kWh/100km)", "highwayElectricConsumptionWltp", property.highwayElectricConsumptionWltp, (value) => handleVariableChange("highwayElectricConsumptionWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Stromverbrauch Autobahn WLTP (kWh/100km)", "autobahnElectricConsumptionWltp", property.autobahnElectricConsumptionWltp, (value) => handleVariableChange("autobahnElectricConsumptionWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch entladener Batterie Innenstadt WLTP (l/100km, kg/100km)", "cityFuelConsumptionDischargedBatteryWltp", property.cityFuelConsumptionDischargedBatteryWltp, (value) => handleVariableChange("cityFuelConsumptionDischargedBatteryWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch entladener Batterie Stadtrand WLTP (l/100km, kg/100km)", "suburbanFuelConsumptionDischargedBatteryWltp", property.suburbanFuelConsumptionDischargedBatteryWltp, (value) => handleVariableChange("suburbanFuelConsumptionDischargedBatteryWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch entladener Batterie Landstraße WLTP (l/100km, kg/100km)", "highwayFuelConsumptionDischargedBatteryWltp", property.highwayFuelConsumptionDischargedBatteryWltp, (value) => handleVariableChange("highwayFuelConsumptionDischargedBatteryWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verbrauch entladener Batterie Autobahn WLTP (l/100km, kg/100km)", "autobahnFuelConsumptionDischargedBatteryWltp", property.autobahnFuelConsumptionDischargedBatteryWltp, (value) => handleVariableChange("autobahnFuelConsumptionDischargedBatteryWltp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Link PkwLabel", "vehicleLabelLink", property.vehicleLabelLink, (value) => handleVariableChange("vehicleLabelLink", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Mittlerer CO2-Preis pro Tonne (Euro)", "averageCo2PricePerTon", property.averageCo2PricePerTon, (value) => handleVariableChange("averageCo2PricePerTon", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Mittlerer CO2-Preis akkumuliert (Euro)", "accumulatedAverageCo2Price", property.accumulatedAverageCo2Price, (value) => handleVariableChange("accumulatedAverageCo2Price", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Niedriger CO2-Preis pro Tonne (Euro)", "lowCo2PricePerTon", property.lowCo2PricePerTon, (value) => handleVariableChange("lowCo2PricePerTon", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Niedriger CO2-Preis akkumuliert (Euro)", "accumulatedLowCo2Price", property.accumulatedLowCo2Price, (value) => handleVariableChange("accumulatedLowCo2Price", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Hoher CO2-Preis pro Tonne (Euro)", "highCo2PricePerTon", property.highCo2PricePerTon, (value) => handleVariableChange("highCo2PricePerTon", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Hoher CO2-Preis akkumuliert (Euro)", "accumulatedHighCo2Price", property.accumulatedHighCo2Price, (value) => handleVariableChange("accumulatedHighCo2Price", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Kraftstoffpreis (Referenz) (Euro/l)", "fuelPriceReference", property.fuelPriceReference, (value) => handleVariableChange("fuelPriceReference", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Strompreis (Euro/kWh)", "electricityPrice", property.electricityPrice, (value) => handleVariableChange("electricityPrice", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Jahr der Energiekosten bei 15.000 km Jahresfahrleistung", "energyCostYear", property.energyCostYear, (value) => handleVariableChange("energyCostYear", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Energiekosten bei 15.000 km Jahresfahrleistung (Euro)", "annualEnergyCost", property.annualEnergyCost, (value) => handleVariableChange("annualEnergyCost", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Kfz-Steuer p.a. (Euro)", "vehicleTaxPerYear", property.vehicleTaxPerYear, (value) => handleVariableChange("vehicleTaxPerYear", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Zeitrahmen (von) CO2-Kosten (Jahr)", "co2CostStartYear", property.co2CostStartYear, (value) => handleVariableChange("co2CostStartYear", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Zeitrahmen (bis) CO2-Kosten (Jahr)", "co2CostEndYear", property.co2CostEndYear, (value) => handleVariableChange("co2CostEndYear", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("URL KFZ-Detailseite", "vehicleDetailPageUrl", property.vehicleDetailPageUrl, (value) => handleVariableChange("vehicleDetailPageUrl", value))}</div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="CO2-Klasse (WLTP)"
                      fieldName="co2ClassWltp"
                      value={property.co2ClassWltp}
                      options={options.co2ClassWltp}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="CO2-Klasse (WLTP) gewichtet, kombiniert"
                      fieldName="weightedCo2ClassWltp"
                      value={property.weightedCo2ClassWltp}
                      options={options.weightedCo2ClassWltp}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="CO2-Klasse (WLTP) bei entladener Batterie"
                      fieldName="co2ClassWltpDischargedBattery"
                      value={property.co2ClassWltpDischargedBattery}
                      options={options.co2ClassWltpDischargedBattery}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                </div>
              )}
            </div>
            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'leasingKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('leasingKomponenten')}>
                Leasing
                <div className="arrow"></div>
              </div>
              {expandedSection === 'leasingKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderEditableDoubleField("Effektiver Jahreszins", "leasingAnnualInterestRate", property.leasingAnnualInterestRate, (value) => handleVariableChange("leasingAnnualInterestRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Monatliche Rate (Euro)", "leasingMonthlyRate", property.leasingMonthlyRate, (value) => handleVariableChange("leasingMonthlyRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laufzeit (Monate)", "leasingDuration", property.leasingDuration, (value) => handleVariableChange("leasingDuration", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Anzahlung (Euro)", "leasingDownPayment", property.leasingDownPayment, (value) => handleVariableChange("leasingDownPayment", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Schlussrate (Euro)", "leasingFinalRate", property.leasingFinalRate, (value) => handleVariableChange("leasingFinalRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Anbieterbank", "leasingBank", property.leasingBank, (value) => handleVariableChange("leasingBank", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Leasing-Restwert (Euro)", "leasingResidualValue", property.leasingResidualValue, (value) => handleVariableChange("leasingResidualValue", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Leasing-Mehrkilometer (Cent)", "extraKilometerCost", property.extraKilometerCost, (value) => handleVariableChange("extraKilometerCost", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Leasing-Minderkilometer (Cent)", "underKilometerCredit", property.underKilometerCredit, (value) => handleVariableChange("underKilometerCredit", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Leasing-Gesamtbetrag (Euro)", "totalLeasingAmount", property.totalLeasingAmount, (value) => handleVariableChange("totalLeasingAmount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Soll-Zinssatz", "leasingNominalInterestRate", property.leasingNominalInterestRate, (value) => handleVariableChange("leasingNominalInterestRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Art des Soll-Zinssatzes", "leasingInterestRateType", property.leasingInterestRateType, (value) => handleVariableChange("leasingInterestRateType", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Bedingungen Leasingvorschlag", "leasingConditions", property.leasingConditions, (value) => handleVariableChange("leasingConditions", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Nettokreditbetrag (Leasing)", "leasingNetLoanAmount", property.leasingNetLoanAmount, (value) => handleVariableChange("leasingNetLoanAmount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Leasing Restwertabschlag", "leasingResidualValueDiscount", property.leasingResidualValueDiscount, (value) => handleVariableChange("leasingResidualValueDiscount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Leasing Sonderkonditionen", "leasingSpecialConditions", property.leasingSpecialConditions, (value) => handleVariableChange("leasingSpecialConditions", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Leasingmatrix", "leasingMatrix", property.leasingMatrix, (value) => handleVariableChange("leasingMatrix", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Wartung pro Monat (Euro)", "maintenancePerMonth", property.maintenancePerMonth, (value) => handleVariableChange("maintenancePerMonth", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Verschleiß pro Monat (Euro)", "wearAndTearPerMonth", property.wearAndTearPerMonth, (value) => handleVariableChange("wearAndTearPerMonth", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Lieferdatum (Auslieferung Datum)", "deliveryDate", property.deliveryDate, (date) => handleVariableChange("deliveryDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Lagerplatz", "storageLocation", property.storageLocation, (value) => handleVariableChange("storageLocation", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Kennzeichen", "licensePlate", property.licensePlate, (value) => handleVariableChange("licensePlate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Aktionscode", "actionCode", property.actionCode, (value) => handleVariableChange("actionCode", value))}</div>
                  <div className="form-group-horizontal">
                    {property.maintenanceAndWearCombination === "null" ? (
                      renderEditableTextField(
                        "Wartung & Verschleiß in Kombination pro Monat",
                        "maintenanceAndWearCombination",
                        "keine Angabe",
                        () => { }
                      )
                    ) : property.maintenanceAndWearCombination === "0" ? (
                      renderEditableTextField(
                        "Wartung & Verschleiß in Kombination pro Monat",
                        "maintenanceAndWearCombination",
                        "inklusive",
                        () => { }
                      )
                    ) : (
                      renderEditableDoubleField(
                        "Wartung & Verschleiß in Kombination pro Monat",
                        "maintenanceAndWearCombination",
                        String(property.maintenanceAndWearCombination), // Wert als String für Editierbarkeit
                        (value) => handleVariableChange("maintenanceAndWearCombination", value) // Konvertierung des Werts in Integer beim Ändern
                      )
                    )}
                  </div>
                  {/* <div className="form-group-horizontal">
                    <DropdownField
                      label="Wartung & Verschleiß in Kombination pro Monat"
                      fieldName="maintenanceAndWearCombination"
                      value={property.maintenanceAndWearCombination}
                      options={options.maintenanceAndWearCombination}
                      onChange={handleVariableChangeDropdown}
                    /></div> */}
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Haftpflicht pro Monat"
                      fieldName="liabilityInsurancePerMonth"
                      value={property.liabilityInsurancePerMonth}
                      options={options.liabilityInsurancePerMonth}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Haftpflicht SB"
                      fieldName="liabilityDeductible"
                      value={property.liabilityDeductible}
                      options={options.liabilityDeductible}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Vollkasko pro Monat"
                      fieldName="fullCoveragePerMonth"
                      value={property.fullCoveragePerMonth}
                      options={options.fullCoveragePerMonth}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Vollkasko SB"
                      fieldName="fullCoverageDeductible"
                      value={property.fullCoverageDeductible}
                      options={options.fullCoverageDeductible}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="KFZ-Lieferung einmalig"
                      fieldName="oneTimeVehicleDeliveryCost"
                      value={property.oneTimeVehicleDeliveryCost}
                      options={options.oneTimeVehicleDeliveryCost}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="KFZ-Steuer pro Monat"
                      fieldName="vehicleTaxPerMonth"
                      value={property.vehicleTaxPerMonth}
                      options={options.vehicleTaxPerMonth}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="8-fach Bereifung pro Monat"
                      fieldName="tireCostPerMonth"
                      value={property.tireCostPerMonth}
                      options={options.tireCostPerMonth}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="TÜV pro Monat"
                      fieldName="tuvCostPerMonth"
                      value={property.tuvCostPerMonth}
                      options={options.tuvCostPerMonth}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Rückgabeversicherung pro Monat"
                      fieldName="returnInsurancePerMonth"
                      value={property.returnInsurancePerMonth}
                      options={options.returnInsurancePerMonth}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Zulassungskosten einmalig"
                      fieldName="oneTimeRegistrationCost"
                      value={property.oneTimeRegistrationCost}
                      options={options.oneTimeRegistrationCost}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Bafa Bonus"
                      fieldName="bafaBonus"
                      value={property.bafaBonus}
                      options={options.bafaBonus}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                </div>
              )}
            </div>
            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'financingKomponenten' ? 'open' : ''}`} onClick={() => toggleSection('financingKomponenten')}>
                Finanzierung
                <div className="arrow"></div>
              </div>
              {expandedSection === 'financingKomponenten' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderEditableDoubleField("Effektiver Jahreszins", "annualInterestRate", property.annualInterestRate, (value) => handleVariableChange("annualInterestRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Monatliche Rate (Euro)", "monthlyRate", property.monthlyRate, (value) => handleVariableChange("monthlyRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Anzahlung (Euro)", "downPayment", property.downPayment, (value) => handleVariableChange("downPayment", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Schlussrate (Euro)", "finalRate", property.finalRate, (value) => handleVariableChange("finalRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Bruttokreditbetrag (Euro)", "grossLoanAmount", property.grossLoanAmount, (value) => handleVariableChange("grossLoanAmount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abschlussgebühren (Euro)", "closingFees", property.closingFees, (value) => handleVariableChange("closingFees", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Nettokreditbetrag (Euro)", "netLoanAmount", property.netLoanAmount, (value) => handleVariableChange("netLoanAmount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Anbieterbank", "financingBank", property.financingBank, (value) => handleVariableChange("financingBank", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Soll-Zinssatz", "nominalInterestRate", property.nominalInterestRate, (value) => handleVariableChange("nominalInterestRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Bedingungen Finanzierungsvorschlag", "financingConditions", property.financingConditions, (value) => handleVariableChange("financingConditions", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Ehemalige Preisempfehlung (UVP)", "formerMsrp", property.formerMsrp, (value) => handleVariableChange("formerMsrp", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Laufleistung (Finanzierung)", "financingMileage", property.financingMileage, (value) => handleVariableChange("financingMileage", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Bruttokreditbetrag", "grossLoanAmount", property.grossLoanAmount, (value) => handleVariableChange("grossLoanAmount", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Abschlussgebühren", "closingFees", property.closingFees, (value) => handleVariableChange("closingFees", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Ratenabsicherung", "installmentInsurance", property.installmentInsurance, (value) => handleVariableChange("installmentInsurance", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Anbieterbank (Finanzierung)", "financingBank", property.financingBank, (value) => handleVariableChange("financingBank", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Nominal Zinssatz", "nominalInterestRate", property.nominalInterestRate, (value) => handleVariableChange("nominalInterestRate", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Art des Soll-Zinssatzes", "interestRateType", property.interestRateType, (value) => handleVariableChange("interestRateType", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Lieferdatum (Finanzierung)", "deliveryDate", property.deliveryDate, (date) => handleVariableChange("deliveryDate", date))}</div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Laufzeit"
                      fieldName="durationInMonths"
                      value={property.durationInMonths}
                      options={options.durationInMonths}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Ratenabsicherung"
                      fieldName="installmentInsurance"
                      value={property.installmentInsurance}
                      options={options.installmentInsurance}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Art des Soll-Zinssatzes"
                      fieldName="interestRateType"
                      value={property.interestRateType}
                      options={options.interestRateType}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                </div>
              )}
            </div>

            <div className="expandable-section">
              <div className={`expandable-header ${expandedSection === 'andere' ? 'open' : ''}`} onClick={() => toggleSection('andere')}>
                Andere
                <div className="arrow"></div>
              </div>
              {expandedSection === 'andere' && (
                <div className="expandable-content">
                  <div className="form-group-horizontal">{renderCheckboxField("Anhängerkupplung", "towBar", property.towBar)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Dritte Sitzreihe", "thirdRowSeats", property.thirdRowSeats)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Gepäckraumabdeckung", "luggageCompartmentCover", property.luggageCompartmentCover)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Regensensor", "rainSensor", property.rainSensor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Lichtsensor", "lightSensor", property.lightSensor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Wärmeschutzglas", "thermalInsulatingGlass", property.thermalInsulatingGlass)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Beheizbare Frontscheibe", "heatedWindshield", property.heatedWindshield)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Beheizbare Scheibenwaschdüsen", "heatedWindshieldWashers", property.heatedWindshieldWashers)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kühlfach im Handschuhfach", "gloveboxCooler", property.gloveboxCooler)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Thermal Cupholder", "thermalCupholder", property.thermalCupholder)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Ambiente-Duftsysteme", "ambientFragranceSystems", property.ambientFragranceSystems)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Oldtimer", "vintageCar", property.vintageCar)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Beschädigtes Fahrzeug", "damagedVehicle", property.damagedVehicle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Taxi", "taxi", property.taxi)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Behindertengerecht", "handicappedAccessible", property.handicappedAccessible)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Jahreswagen", "annualCar", property.annualCar)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Neufahrzeug", "newCar", property.newCar)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Garantie", "warrantyIncluded", property.warrantyIncluded)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Vorführfahrzeug", "demonstrationVehicle", property.demonstrationVehicle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Scheckheftgepflegt", "serviceBookMaintained", property.serviceBookMaintained)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kommunalfahrzeug", "municipal", property.municipal)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kran", "crane", property.crane)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Retarder/Intarder", "retarderOrIntarder", property.retarderOrIntarder)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Schlafplatz", "sleepingPlace", property.sleepingPlace)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("TV", "television", property.television)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("WC", "toilet", property.toilet)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Ladebordwand", "tailLift", property.tailLift)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Trennwand", "splittingUp", property.splittingUp)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Küche", "kitchen", property.kitchen)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kühlbox", "coolBox", property.coolBox)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Schlafsitze", "sleeperSeats", property.sleeperSeats)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Koffer", "trunkCase", property.trunkCase)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Scheibe", "windshield", property.windshield)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Standklima", "stationaryAirConditioning", property.stationaryAirConditioning)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Etagenbett", "bunkBed", property.bunkBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Festbett", "fixedBed", property.fixedBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heckgarage", "rearGarage", property.rearGarage)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Markise", "awning", property.awning)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sep. Dusche", "separateShower", property.separateShower)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Solaranlage", "solarPanel", property.solarPanel)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Inserat als 'neu' markieren", "markedAsNew", property.markedAsNew)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Nichtraucherfahrzeug", "nonSmokerVehicle", property.nonSmokerVehicle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Unfallfahrzeug", "accidentVehicle", property.accidentVehicle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Vermietbar", "rental", property.rental)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Auktionsfahrzeug", "auctionVehicle", property.auctionVehicle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kundenauftrag", "customerOrder", property.customerOrder)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Vorlauf", "preOrder", property.preOrder)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Wartung enthalten", "includedMaintenance", property.includedMaintenance)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Verschleiß enthalten", "includedWear", property.includedWear)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Überführung enthalten", "deliveryIncluded", property.deliveryIncluded)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Zulassung enthalten", "registrationIncluded", property.registrationIncluded)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("KFZ-Lieferung enthalten", "vehicleDeliveryIncluded", property.vehicleDeliveryIncluded)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Homepage Exklusiv", "exclusiveHomepage", property.exclusiveHomepage)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Youngtimer", "youngtimer", property.youngtimer)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Musterfahrzeug / Virtuelles Fahrzeug", "virtualVehicle", property.virtualVehicle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Onlinekauf", "onlinePurchase", property.onlinePurchase)}</div>

                  <div className="form-group-horizontal">{renderCheckboxField("Batteriezertifikat vorhanden", "batteryCertificate", property.batteryCertificate)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Virtuelle Seitenspiegel", "virtualMirrors", property.virtualMirrors)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Service neu", "serviceNew", property.serviceNew)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Navigationsvorbereitung", "navigationPreparation", property.navigationPreparation)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Farbe matt", "mattColor", property.mattColor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Rechtslenker", "rightHandDrive", property.rightHandDrive)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Bidirektionales Laden", "bidirectionalCharging", property.bidirectionalCharging)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Faltdach", "foldingRoof", property.foldingRoof)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Ausparkassistent", "parkAssist", property.parkAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Adaptives Fahrwerk", "adaptiveChassis", property.adaptiveChassis)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Anhängerrangierassistent", "trailerAssist", property.trailerAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Vorbereitung Anhängerkupplung", "trailerHitchPrep", property.trailerHitchPrep)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Stoppie-Control (Motorrad)", "stoppieControl", property.stoppieControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Wheelie-Control (Motorrad)", "wheelieControl", property.wheelieControl)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Kurven-ABS (Motorrad)", "corneringABS", property.corneringABS)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Schaltautomat (Motorrad)", "quickShifter", property.quickShifter)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Blipper (Motorrad)", "blipper", property.blipper)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Griffheizung (Motorrad)", "heatedGrips", property.heatedGrips)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Semi-aktives Fahrwerk (Motorrad)", "semiActiveSuspension", property.semiActiveSuspension)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Drossel (Motorrad)", "throttleRestriction", property.throttleRestriction)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("SAT-Anlage (Wohnmobile)", "satelliteSystem", property.satelliteSystem)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Fahrradträger (Wohnmobile)", "bikeRack", property.bikeRack)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Rangierhilfe (Wohnmobile)", "maneuveringAssist", property.maneuveringAssist)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Warmwasser (Wohnmobile)", "hotWater", property.hotWater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Queensize Bett (Wohnmobile)", "queenBed", property.queenBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Französisches Bett (Wohnmobile)", "frenchBed", property.frenchBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Einzelbett (Wohnmobile)", "singleBed", property.singleBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Doppelbett hinten (Wohnmobile)", "rearDoubleBed", property.rearDoubleBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Doppelbett vorne (Wohnmobile)", "frontDoubleBed", property.frontDoubleBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Hubbetten (Klapp) (Wohnmobile)", "foldingBeds", property.foldingBeds)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Aufstelldach-Doppelbett (Wohnmobile)", "popUpRoofDoubleBed", property.popUpRoofDoubleBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sitz Umbaubett (Wohnmobile)", "convertibleSeatBed", property.convertibleSeatBed)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sitzgruppe L-Form (Wohnmobile)", "lShapeSeating", property.lShapeSeating)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Sitzgruppe Bar Form (Wohnmobile)", "barSeating", property.barSeating)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heizung Gas (Wohnmobile)", "gasHeater", property.gasHeater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heizung Diesel (Wohnmobile)", "dieselHeater", property.dieselHeater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heizung Benzin (Wohnmobile)", "petrolHeater", property.petrolHeater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heizung Strom (Wohnmobile)", "electricHeater", property.electricHeater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Heizung Holz (Wohnmobile)", "woodHeater", property.woodHeater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Lenkachse", "steeringAxle", property.steeringAxle)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Colorverglasung", "tintedWindows", property.tintedWindows)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Nackenheizung (AIRSCARF)", "neckHeater", property.neckHeater)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Tuning", "tuning", property.tuning)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Waschwasserstandsanzeige", "washerFluidLevelIndicator", property.washerFluidLevelIndicator)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Windschott (Cabrio)", "windDeflector", property.windDeflector)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Zentralverriegelung mit Funk", "remoteCentralLocking", property.remoteCentralLocking)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Differentialsperre (nur Nutzfahrzeuge)", "differentialLock", property.differentialLock)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Beifahrer-Doppelsitz (nur Nutzfahrzeuge)", "passengerDoubleSeat", property.passengerDoubleSeat)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Holzboden (nur Nutzfahrzeuge)", "woodFloor", property.woodFloor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Drehschemel (nur Nutzfahrzeuge)", "turntable", property.turntable)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Hochlader (nur Nutzfahrzeuge)", "highLoader", property.highLoader)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Innenverkleidung (nur Nutzfahrzeuge)", "interiorLining", property.interiorLining)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Ladeboden - Variabel (nur Nutzfahrzeuge)", "adjustableLoadingFloor", property.adjustableLoadingFloor)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Ladedruck & Temperaturanzeigen (nur Nutzfahrzeuge)", "boostPressureTempIndicators", property.boostPressureTempIndicators)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Zusatzscheinwerfer (nur Nutzfahrzeuge)", "additionalHeadlights", property.additionalHeadlights)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Seilwinde (nur Nutzfahrzeuge)", "winch", property.winch)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Tachograf (nur Nutzfahrzeuge)", "tachograph", property.tachograph)}</div>
                  <div className="form-group-horizontal">{renderCheckboxField("Verkleidung (nur Motorräder)", "motorcycleFairing", property.motorcycleFairing)}</div>


                  <div className="form-group-horizontal">{renderEditableTextField("Kundennummer/Filiale", "customerNumberOrLocation", property.customerNumberOrLocation, (value) => handleVariableChange("customerNumberOrLocation", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Interne Nummer", "internalNumber", property.internalNumber, (value) => handleVariableChange("internalNumber", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Kategorie", "category", property.category, (value) => handleVariableChange("category", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Bemerkung", "notes", property.notes, (value) => handleVariableChange("notes", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("Währung", "currency", property.currency, (value) => handleVariableChange("currency", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("HU Datum", "huDate", property.huDate, (date) => handleVariableChange("huDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("EZ Datum", "registrationDate", property.registrationDate, (date) => handleVariableChange("registrationDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Lieferdatum", "shipmentDate", property.shipmentDate, (date) => handleVariableChange("shipmentDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Überführungskosten (Euro)", "transferCost", property.transferCost, (value) => handleVariableChange("transferCost", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("HSN", "hsn", property.hsn, (value) => handleVariableChange("hsn", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("TSN", "tsn", property.tsn, (value) => handleVariableChange("tsn", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("Händlerpreis", "dealerPrice", property.dealerPrice, (value) => handleVariableChange("dealerPrice", value))}</div>
                  <div className="form-group-horizontal">{renderEditableTextField("KFZ-Ansprechpartner Email", "vehicleContactEmail", property.vehicleContactEmail, (value) => handleVariableChange("vehicleContactEmail", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDateField("Letzte Wartung Datum", "lastServiceDate", property.lastServiceDate, (date) => handleVariableChange("lastServiceDate", date))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Letzte Wartung KM", "lastServiceKM", property.lastServiceKM, (value) => handleVariableChange("lastServiceKM", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Anhängelast gebremst (kg)", "towingCapacityBraked", property.towingCapacityBraked, (value) => handleVariableChange("towingCapacityBraked", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Anhängelast ungebremst (kg)", "towingCapacityUnbraked", property.towingCapacityUnbraked, (value) => handleVariableChange("towingCapacityUnbraked", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Stützlast AHK (kg)", "hitchLoad", property.hitchLoad, (value) => handleVariableChange("hitchLoad", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Tankgröße (Liter)", "tankSize", property.tankSize, (value) => handleVariableChange("tankSize", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Sitzhöhe (mm)", "seatHeight", property.seatHeight, (value) => handleVariableChange("seatHeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Reichweite (km)", "motorcycleRange", property.motorcycleRange, (value) => handleVariableChange("motorcycleRange", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Stehhöhe (cm)", "standingHeight", property.standingHeight, (value) => handleVariableChange("standingHeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Betriebsgewicht (kg)", "operatingWeight", property.operatingWeight, (value) => handleVariableChange("operatingWeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Felgengröße (Zoll)", "rimSize", property.rimSize, (value) => handleVariableChange("rimSize", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Zulässiges Gesamtgewicht (KG)", "grossVehicleWeight", property.grossVehicleWeight, (value) => handleVariableChange("grossVehicleWeight", value))}</div>
                  <div className="form-group-horizontal">{renderEditableNumericField("Batterie-Garantie (Monate)", "batteryWarranty", property.batteryWarranty, (value) => handleVariableChange("batteryWarranty", value))}</div>
                  <div className="form-group-horizontal">{renderEditableDoubleField("MwSt. Satz", "vatRate", property.vatRate, (value) => handleVariableChange("vatRate", value))}</div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Lieferfrist"
                      fieldName="deliveryTimeframe"
                      value={property.deliveryTimeframe}
                      options={options.deliveryTimeframe}
                      onChange={handleVariableChangeDropdown}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Kraftstoffart"
                      fieldName="fuelType"
                      value={property.fuelType}
                      options={options.fuelType}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Getriebeart"
                      fieldName="transmissionType"
                      value={property.transmissionType}
                      options={options.transmissionType}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  {/* <div className="form-group-horizontal">{renderEditableDateField("Hereinnahmedatum", "acquisitionDate", property.acquisitionDate, (date) => handleVariableChange("acquisitionDate", date))}</div> */}
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Rotstiftpreis"
                      fieldName="redPencilPrice"
                      value={property.redPencilPrice}
                      options={options.redPencilPrice}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="eBay-K Export"
                      fieldName="ebayExport"
                      value={property.ebayExport}
                      options={options.ebayExport}
                      onChange={handleVariableChangeDropdownInt}
                    /></div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Elektr. Sitzeinstellung Memory-Funktion"
                      fieldName="electricSeatMemoryFunction"
                      value={property.electricSeatMemoryFunction}
                      options={options.electricSeatMemoryFunction}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Länge Typ"
                      fieldName="lengthType"
                      value={property.lengthType}
                      options={options.lengthType}
                      onChange={handleVariableChangeDropdown}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Höhe Typ"
                      fieldName="heightType"
                      value={property.heightType}
                      options={options.heightType}
                      onChange={handleVariableChangeDropdown}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownField
                      label="Radstand (Typ)"
                      fieldName="wheelbaseType"
                      value={property.wheelbaseType}
                      options={options.wheelbaseType}
                      onChange={handleVariableChangeDropdown}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Batterieladung Steckertyp"
                      fieldName="chargingPlugType"
                      value={property.chargingPlugType}
                      options={options.chargingPlugType}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Zuglast AHK"
                      fieldName="hitchLoadCapacity"
                      value={property.hitchLoadCapacity}
                      options={options.hitchLoadCapacity}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Anhängerkupplung-NFZ Typ"
                      fieldName="trailerHitchType"
                      value={property.trailerHitchType}
                      options={options.trailerHitchType}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Hintertür (Art)"
                      fieldName="rearDoorType"
                      value={property.rearDoorType}
                      options={options.rearDoorType}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Hochdach (Art)"
                      fieldName="highRoofType"
                      value={property.highRoofType}
                      options={options.highRoofType}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>
                  <div className="form-group-horizontal">
                    <DropdownFieldInt
                      label="Zustandsbericht"
                      fieldName="conditionReport"
                      value={property.conditionReport}
                      options={options.conditionReport}
                      onChange={handleVariableChangeDropdownInt}
                    />
                  </div>

                </div>
              )}
            </div>


            <div className="button-group-centered">
              <button type="submit" className="button save-button">Speichern</button>
              <button type="button" onClick={() => setEditMode(false)} className="button cancel-button">Abbrechen</button>
            </div>
          </form>
        ) : (
          <>
            <div className="property-detail"><span className="property-label">Marke:</span> {property.type}</div>
            <div className="property-detail"><span className="property-label">Modell:</span> {property.model}</div>
            <div className="property-detail"><span className="property-label">Preis:</span> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(property.price)}</div>
            <div className="property-detail"><span className="property-label">Ort:</span> {property.city}</div>
            <div className="property-detail"><span className="property-label">Bundesland:</span> {property.state}</div>
            <div className="property-detail"><span className="property-label">Erstzulassungsdatum:</span> {property.year}-{property.month}</div>
            <div className="property-detail"><span className="property-label">Leistung:</span> {property.performance} PS</div>
            <div className="property-detail"><span className="property-label">Kilometerstand:</span> {property.mileage} km</div>
            <div className="property-detail"><span className="property-label">Gebrauchtwagen:</span> {property.usedCar ? 'Ja' : 'Nein'}</div>
            <div className="property-detail"><span className="property-label">Radar:</span> {property.radar ? 'Ja' : 'Nein'}</div>
            <button onClick={() => setEditMode(true)} className="button edit-button">Bearbeiten</button>
            <button onClick={() => navigate(`/images/${property.id}`)} className="button images-button">Bilder</button>
            <button onClick={() => setShowDeleteConfirm(true)} className="button details-delete-button">Löschen</button>
          </>
        )}
      </div>
      {showDeleteConfirm && (
        <div className="delete-confirm-popup">
          <div className="delete-confirm-content">
            <h3>Sind Sie sicher, dass Sie dieses Fahrzeug löschen möchten?</h3>
            <div className="button-group-centered">
              <button onClick={handleDelete} className="button details-delete-button">Löschen</button>
              <button onClick={() => setShowDeleteConfirm(false)} className="button cancel-button">Abbrechen</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailView;
