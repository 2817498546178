import React, { useState, useEffect, useRef } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { getGeocode } from 'use-places-autocomplete';

interface AddressSearchProps {
  onSelectAddress: (address: string, state: string) => void;
  onInputAddress: () => void;
  isValidAddress: boolean;
  initialValue: string;
}

const AddressSearch: React.FC<AddressSearchProps> = ({ onSelectAddress, onInputAddress, isValidAddress, initialValue }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [status, setStatus] = useState('IDLE');
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input element

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (inputRef.current && status !== 'LOADING') {
      inputRef.current.focus(); // Focus the input field after loading
    }
  }, [status]); // Re-focus the input field whenever status changes

  const fetchSuggestions = async (input: string) => {
    if (!input) return;
    setStatus('LOADING');

    try {
      const response = await fetch(`https://automatch-backend-585373068068.europe-west3.run.app/api/autocomplete?input=${input}`);
      if (!response.ok) {
        throw new Error('Failed to fetch autocomplete data');
      }
      const data = await response.json();
      setSuggestions(data.predictions);
      setStatus('OK');
    } catch (error) {
      console.error('Error fetching autocomplete:', error);
      setStatus('ERROR');
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onInputAddress();
    fetchSuggestions(e.target.value);
  };

  const handleSelect = async (address: string) => {
    const city = address.split(',')[0];
    setInputValue(city);
    setSuggestions([]);
    try {
      const results = await getGeocode({ address });
      const state = results[0].address_components.find(component => component.types.includes("administrative_area_level_1"))?.long_name || '';
      onSelectAddress(city, state);
    } catch (error) {
      console.error('Error fetching geocode details:', error);
      onSelectAddress(city, '');
    }
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        ref={inputRef} // Assign ref to the input
        value={inputValue}
        onChange={handleInput}
        disabled={status === 'LOADING'}
        placeholder="Ort eingeben"
        className={!isValidAddress ? 'invalid-input' : ''}
      />
      {inputValue && status === 'OK' && (
        <ComboboxPopover>
          <ComboboxList>
            {suggestions.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      )}
    </Combobox>
  );
};

export default AddressSearch;





// import React, { useState, useEffect } from 'react';
// import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
// import {
//   Combobox,
//   ComboboxInput,
//   ComboboxPopover,
//   ComboboxList,
//   ComboboxOption
// } from '@reach/combobox';
// import '@reach/combobox/styles.css';

// interface AddressSearchProps {
//   onSelectAddress: (address: string, state: string) => void; // Update this prop to include state
//   onInputAddress: () => void; // New prop to handle address input change
//   isValidAddress: boolean; // Prop to determine if the address is valid
//   initialValue: string; // Initial value for the address input
// }

// const AddressSearch: React.FC<AddressSearchProps> = ({ onSelectAddress, onInputAddress, isValidAddress, initialValue }) => {
//   const {
//     ready,
//     value,
//     suggestions: { status, data },
//     setValue,
//     clearSuggestions
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       componentRestrictions: { country: 'de' },
//       types: ['(cities)']
//     },
//     debounce: 300
//   });

//   useEffect(() => {
//     if (initialValue) {
//       setValue(initialValue, false); // Set initial value without triggering dropdown
//     }
//   }, [initialValue, setValue]);

//   const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setValue(e.target.value);
//     onInputAddress(); // Call onInputAddress when input changes
//   };

//   const handleSelect = async (address: string) => {
//     console.log("ADDRESS:", address);
//     const city = address.split(',')[0]; // Extract only the city name
//     setValue(city, false); // Set selected city without triggering dropdown
//     clearSuggestions();

//     // Fetch additional details about the selected address
//     try {
//       const results = await getGeocode({ address });
//       const state = results[0].address_components.find(component =>
//         component.types.includes("administrative_area_level_1")
//       )?.long_name || '';
//       onSelectAddress(city, state); // Pass city and state to the parent component
//     } catch (error) {
//       console.error('Error fetching geocode details:', error);
//       onSelectAddress(city, ''); // Pass city and empty state if there's an error
//     }
//   };

//   return (
//     <Combobox onSelect={handleSelect}>
//       <ComboboxInput
//         value={value}
//         onChange={handleInput}
//         disabled={!ready}
//         placeholder="Ort eingeben"
//         className={!isValidAddress ? 'invalid-input' : ''} // Apply invalid-input class if the address is not valid
//       />
//       {value && status === 'OK' && (
//         <ComboboxPopover>
//           <ComboboxList>
//             {data.map(({ place_id, description }) => (
//               <ComboboxOption key={place_id} value={description} />
//             ))}
//           </ComboboxList>
//         </ComboboxPopover>
//       )}
//     </Combobox>
//   );
// };

// export default AddressSearch;

